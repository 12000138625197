import axios from "axios";

const getBaseUrl =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "http://busplan.subaru-iq.com/api"
    : "http://subaru-businessplanner.cranemorleydev.com/api";

const getSSO =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://partners.subaru.com/oamfed/idp/initiatesso?providerid=https://assistant.subaru-iq.com"
    : "https://partners-qa.subaru.com/oamfed/idp/initiatesso?providerid=https://subaru-va.cmclient.com";

const client = axios.create({
  baseURL: getBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

/*
client.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        "Content-Type": "application/json",
      },
    };
  },
  (error) => {
    Promise.reject(error);
  }
);*/

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      window.location.href = `${getSSO}&returnurl=${window.location.href}`;
    }
    return Promise.reject(error.response.data);
  }
);

const { get, post, put, patch, delete: destroy } = client;
export { get, post, put, patch, destroy };
export default client;
