import React, { useEffect, useState } from "react";

import {
  Accordion,
  Container,
  Col,
  Form,
  Button,
  Dropdown,
  Row,
  Spinner,
} from "react-bootstrap";

import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";
//import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faThumbtack,
  faSlash,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import { MetricAreaChart } from "./charts";
import InfoBadge from "./InfoBadge";
import classNames from "classnames";

import * as metricApi from "../../services/api/Metrics";
import * as metricColumnApi from "../../services/api/MetricsColumns";
import * as metricTypeApi from "../../services/api/MetricTypes";
import { useParams } from "react-router-dom";
import { v4 as generateUuid } from "uuid";
import { atom, useRecoilState } from "recoil";
import moment from "moment";
import ConfirmDelete from "../elements/ConfirmDelete";

export type MetricType = {
  id: number;
  isTitle: boolean;
  name: string;
};

type Metric = {
  type: MetricType | undefined;
  columns: MetricColumn[];
  collapsed: boolean;
  uuid: string;
  isPinned: boolean;
  gamePlanName?: string;
};

type MetricColumn = {
  value: number;
  uuid: string;
  name: string;
};

export type MetricParentColumnApiData = {
  uuid: string;
  name: string;
  value: string;
};

export type MetricApiData = {
  uuid: string;
  name: string;
  isPinned: boolean;
  metricTypeId: number;
  columns: MetricParentColumnApiData[];
  gamePlanName?: string;
};

const getLastSaved = atom<string>({
  key: "getLastSaved",
  default: "",
});
const getDisabledSaveButton = atom<boolean>({
  key: "getDisabledSaveButton",
  default: true,
});
const getHiddenSpinner = atom<boolean>({
  key: "getHiddenSpinner",
  default: true,
});

export default function Metrics(props: any) {
  const [metricList, setMetricList] = useState<MetricType[]>([]);
  const [metricState, setMetricState] = useState<Metric[]>([]);
  const [metricSearch, setMetricSearch] = useState("");
  const [addMetric, setAddMetric] = useState("");
  const [addFrequency, setAddFrequency] = useState("");
  const [init, setInit] = useState<boolean | null>(false);

  const { uuidActionPlan } = useParams<{ uuidActionPlan: string }>();
  const [saveText, setSaveText] = useRecoilState(getLastSaved);
  const [disableSaveButton, setDisableSaveButton] = useRecoilState(
    getDisabledSaveButton
  );
  const [hideSpinner, setHideSpinner] = useRecoilState(getHiddenSpinner);

  useEffect(() => {
    metricTypeApi.MetricTypes.get()
      .then((typesResponse) => {
        setInit(true);
        setMetricList(typesResponse.data);

        metricApi.Metrics.get(uuidActionPlan)
          .then((metricsResponse) => {
            setMetricState(
              metricsResponse.data.map((item) => {
                return {
                  type: typesResponse.data.find(
                    (_) => _.id === item.metricTypeId
                  ),
                  uuid: item.uuid,
                  columns: item.columns.map((column) => {
                    return {
                      value: parseInt(column.value),
                      uuid: column.uuid,
                      name: column.name,
                    };
                  }),
                  collapsed: false,
                  isPinned: item.isPinned,
                };
              })
            );
          })
          .catch(console.error);
      })
      .catch(console.error);
  }, [setMetricList, uuidActionPlan, setMetricState, setInit]);

  const submitValues = (e: any) => {
    console.log(e.target.value);
  };

  const fieldChangeHandler = (e: any, i: number, j: number, save: boolean) => {
    activeSaveButton();
    const tempMetricState = Array.from(metricState);
    tempMetricState[i].columns[j].value = e.target.value;

    setMetricState(tempMetricState);

    const columnCopy = tempMetricState[i].columns[j];
    const uuidMetric = tempMetricState[i].uuid;

    if (save === true) {
      metricColumnApi.MetricParentColumns.patch(uuidMetric, [
        {
          uuid: columnCopy.uuid,
          value: columnCopy.value.toString(),
          name: columnCopy.name,
        },
      ])
        .then((response) => {
          console.log(response);
          if (response.statusText === "OK") {
            updateSaveButtonAfterSuccess();
          }
        })
        .catch(console.error);
    }
  };

  const addFrequencyHandler = (_: string) => {
    setAddFrequency(_);
    if (addMetric !== "") {
      createNewMetric(addMetric, _);
    }
  };

  const addMetricHandler = (_: string) => {
    setAddMetric(_);
    if (addFrequency !== "") {
      createNewMetric(_, addFrequency);
    }
  };

  const createNewMetric = (myMetric: string, myFrequency: string) => {
    if (myMetric !== "" && myFrequency !== "") {
      const newApiColumns = [];
      newApiColumns.push({ value: "0", uuid: generateUuid(), name: "Base" });
      newApiColumns.push({ value: "0", uuid: generateUuid(), name: "Goal" });
      newApiColumns.push({
        value: "0",
        uuid: generateUuid(),
        name: "#1 " + myFrequency,
      });
      newApiColumns.push({
        value: "0",
        uuid: generateUuid(),
        name: "#2 " + myFrequency,
      });
      newApiColumns.push({
        value: "0",
        uuid: generateUuid(),
        name: "#3 " + myFrequency,
      });
      newApiColumns.push({
        value: "0",
        uuid: generateUuid(),
        name: "#4 " + myFrequency,
      });
      newApiColumns.push({
        value: "0",
        uuid: generateUuid(),
        name: "#5 " + myFrequency,
      });
      newApiColumns.push({
        value: "0",
        uuid: generateUuid(),
        name: "#6 " + myFrequency,
      });
      const newMetric = {
        collapsed: false,
        columns: newApiColumns.map((_) => {
          return { value: 0, uuid: _.uuid, name: _.name };
        }),
        type: metricList.find((_) => _.name === myMetric),
        uuid: generateUuid(),
        isPinned: false,
      };

      activeSaveButton();

      metricApi.Metrics.post(uuidActionPlan, {
        uuid: newMetric.uuid,
        columns: newApiColumns,
        isPinned: newMetric.isPinned,
        name: newMetric?.type?.name ?? "",
        metricTypeId: newMetric?.type?.id ?? 0,
      })
        .then((response) => {
          if (response.statusText === "OK") {
            updateSaveButtonAfterSuccess();
            const tempArray = Array.from(metricState);
            tempArray.push(newMetric);
            setMetricState(tempArray);
          }
        })
        .catch(console.error);

      setAddMetric("");
    }
  };

  const togglePin = (uuid: string, isPinned: boolean) => {
    activeSaveButton();
    setMetricState(
      metricState.map((item) =>
        item.uuid === uuid
          ? {
              ...item,
              isPinned: isPinned,
            }
          : item
      )
    );

    const metricCopy = metricState.find((_) => _.uuid === uuid);
    if (metricCopy) {
      metricApi.Metrics.patch(uuidActionPlan, [
        {
          uuid: uuid,
          isPinned: isPinned,
          metricTypeId: metricCopy?.type?.id ?? 0,
          name: metricCopy?.type?.name ?? "",
          columns: [],
        },
      ])
        .then((response) => {
          if (response.statusText === "OK") {
            updateSaveButtonAfterSuccess();
          }
        })
        .catch(console.error);
    }
  };

  const deleteMetric = (uuid: string) => {
    activeSaveButton();
    metricApi.Metrics.remove(uuid).then((response) => {
      if (response.statusText === "OK") {
        updateSaveButtonAfterSuccess();
        const tempMetrics = metricState.filter((x) => x.uuid !== uuid);
        setMetricState(tempMetrics);
      }
    });
  };

  const confirmDelete = async (data: any, canDelete: boolean) => {
    if (canDelete === true) {
      deleteMetric(data);
    }
  };

  const updateSaveButtonAfterSuccess = () => {
    setSaveText(
      "Last saved at " + moment(new Date().toLocaleString()).format("h:mm A")
    );
    setDisableSaveButton(true);
    setHideSpinner(true);
  };

  const activeSaveButton = () => {
    console.log(saveText + " " + disableSaveButton + " " + hideSpinner);
    setSaveText("");
    setDisableSaveButton(false);
  };

  const toggleCollapse = (uuid: string) => {
    const arrayTemp = Array.from(metricState);
    setMetricState(
      arrayTemp.map((item) =>
        item.uuid === uuid
          ? { ...item, collapsed: item.collapsed === true ? false : true }
          : item
      )
    );
  };

  if (init === true) {
    return (
      <Container fluid className="p-0">
        {props.editable && (
          <Row>
            <Col sm={12} md={6} className="mb-3">
              <Form.Label>
                Measured By
                <InfoBadge>
                  “Measured By” defines how the Game Plan’s results will be
                  measured.
                </InfoBadge>
              </Form.Label>
              <Dropdown>
                <div className="bg-white">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    className="d-flex align-items-center w-100 py-2 px-3"
                  >
                    <span className="flex-grow-1 text-left">
                      {addMetric !== "" ? addMetric : "Add Metric"}
                    </span>
                  </Dropdown.Toggle>
                </div>

                <Dropdown.Menu>
                  <Dropdown.Header>
                    <Form.Control
                      autoFocus
                      placeholder="Search Metric..."
                      onChange={(e) => setMetricSearch(e.target.value)}
                    />
                  </Dropdown.Header>

                  <Dropdown.Divider />

                  {metricList
                    .filter(
                      (_) =>
                        _.name
                          .toLowerCase()
                          .indexOf(metricSearch.toLowerCase()) !== -1
                    )
                    .map((_) =>
                      _.isTitle ? (
                        <>
                          <Dropdown.Divider />
                          <Dropdown.Header>{_.name}</Dropdown.Header>
                        </>
                      ) : (
                        <Dropdown.Item
                          onSelect={() => addMetricHandler(_.name)}
                        >
                          {_.name}
                        </Dropdown.Item>
                      )
                    )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <Form.Label>
                Frequency:{" "}
                <InfoBadge>
                  How often you are retrieving this data? Can be weekly or
                  monthly.
                </InfoBadge>
              </Form.Label>

              <Dropdown>
                <div className="bg-white">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    className="d-flex align-items-center w-100 py-2 px-3"
                  >
                    <span className="flex-grow-1 text-left">
                      {addFrequency !== "" ? addFrequency : "Select..."}
                    </span>
                  </Dropdown.Toggle>
                </div>

                <Dropdown.Menu>
                  {["Month", "Week"].map((_) => (
                    <Dropdown.Item onSelect={() => addFrequencyHandler(_)}>
                      {_}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        )}

        {metricState.map((metric: Metric, i: number) => (
          <Accordion defaultActiveKey={"metric-" + i}>
            <Row key={i}>
              <Col>
                <Form onSubmit={submitValues}>
                  <Container fluid className="bg-white">
                    <Row className="bg-dark text-light py-2">
                      <Col xs={12}>
                        <Accordion.Toggle
                          eventKey={"metric-" + i}
                          as={Button}
                          variant="link"
                          className="float-left p-0 mr-2"
                          data-toggle="button"
                          aria-pressed="false"
                          onClick={() => toggleCollapse(metric.uuid)}
                        >
                          <I
                            icon={
                              metric.collapsed ? faChevronDown : faChevronUp
                            }
                            fixedWidth
                          />
                        </Accordion.Toggle>
                        <span className="float-left">{metric.type?.name}</span>
                        <div className="float-right">
                          {!metric.isPinned ? (
                            <Button
                              variant="link"
                              onClick={() => togglePin(metric.uuid, true)}
                            >
                              <I icon={faThumbtack} className="mr-3" />
                            </Button>
                          ) : (
                            <Button
                              variant="link"
                              className="fa-layers fa-fw mr-3"
                              onClick={() => togglePin(metric.uuid, false)}
                            >
                              <I icon={faThumbtack} />
                              <I icon={faSlash} />
                            </Button>
                          )}
                          <ConfirmDelete
                            defineClass=""
                            data={metric.uuid}
                            selection={confirmDelete}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Accordion.Collapse eventKey={"metric-" + i}>
                      <Row>
                        <Col>
                          <Container fluid className="p-0">
                            {props.editable && (
                              <Row className="text-center mt-3" noGutters>
                                {metric.columns
                                  .map((c) => {
                                    return c.name;
                                  })
                                  .map((name, j) => (
                                    <Form.Group
                                      as={Col}
                                      className="m-0"
                                      controlId={`metric-${metric.type?.id}-${j}`}
                                    >
                                      <Form.Control
                                        type="text"
                                        size="sm"
                                        placeholder="-"
                                        value={metric.columns[j].value}
                                        onChange={(e) =>
                                          fieldChangeHandler(e, i, j, false)
                                        }
                                        onBlur={(e: any) =>
                                          fieldChangeHandler(e, i, j, true)
                                        }
                                        className={classNames("text-center", {
                                          "border-warning": j === 0,
                                          "border-success": j === 1,
                                        })}
                                      />
                                      <Form.Text>{name}</Form.Text>
                                    </Form.Group>
                                  ))}
                              </Row>
                            )}
                          </Container>
                        </Col>
                        <Col xs={12}>
                          <MetricAreaChart
                            data={metricState[i].columns.map((item) => {
                              return item.value;
                            })}
                          />
                        </Col>
                      </Row>
                    </Accordion.Collapse>
                  </Container>
                </Form>
              </Col>
            </Row>
          </Accordion>
        ))}
      </Container>
    );
  } else {
    return (
      <>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </>
    );
  }
}
