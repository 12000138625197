import { get, patch, destroy, post } from "./base";

import type { Priority } from "../../components/pages/businessplan/PrioritizedAreas";

export const Priorities = {
  get: () => get<Priority[]>("/v1/priorities"),
  patch: (priorities: Priority[]) => patch("/v1/priorities", priorities),
  post: (priority: Priority) => post("/v1/priorities", priority),
  remove: (uuid: string) => destroy(`/v1/priorities/${uuid}`),
};
