import React from "react";
import { Cell, Pie, PieChart } from "recharts";

interface IProgressMeter {
  value: number;
}

export default function ProgressMeter({ value }: IProgressMeter) {
  return (
    <PieChart
      width={50}
      height={50}
      className="d-inline-block"
      style={{ margin: -12 }}
    >
      <Pie
        data={[
          { k: "k", v: value },
          { k: "k", v: 100 - value },
        ]}
        dataKey="v"
        nameKey="k"
        innerRadius="70%"
        outerRadius="100%"
        startAngle={90}
        endAngle={-270}
        paddingAngle={0}
      >
        <Cell key="container" fill="var(--primary)" />
        <Cell key="container" fill="#ccc" />
      </Pie>
      <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    </PieChart>
  );
}
