import React from "react";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";

function InfoBadge(props: any) {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover id={`tooltip-${Math.round(Math.random() * 1000)}}`}>
          <Popover.Content>{props.children}</Popover.Content>
        </Popover>
      }
    >
      <sup style={{ fontSize: "1.125rem" }}>
        <Badge pill variant="primary" style={{ padding: "3px 1px" }}>
          <FontAwesomeIcon icon={faQuestion} className="fa-fw" />
        </Badge>
      </sup>
    </OverlayTrigger>
  );
}

export default InfoBadge;
