import React, { useEffect, useRef, useState } from "react";

import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  ProgressBar,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faAdjust,
  faAngleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { GamePlanList } from "./gameplan";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Workbook as api,
  WorkbookApiData,
  WorkbookStepApiData,
  WorkbookStepApiPatchData,
} from "../../services/api/Workbook";
import RichText from "../elements/RichText";

export default function Workbook() {
  const version = 1;

  const { workbookId } = useParams<{ workbookId: string }>();
  const [workbookApiData, setWorkbookApiData] = useState<WorkbookApiData>({
    title: "",
    steps: [],
    workbookStatus: "NOTSTARTED",
    workbookId: parseInt(workbookId),
  });
  const [activeTab, setActiveTab] = useState("step-0");
  const [init, setInit] = useState<boolean | null>(false);

  const businessPlannerRef = useRef<HTMLDivElement>(null);
  const scrollDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    api
      .get(parseInt(workbookId))
      .then((response) => {
        if (response.statusText === "OK") {
          const getResponseData = response.data;
          setWorkbookApiData(getResponseData);
          setInit(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setInit(false);
      });
  }, [workbookId, setInit]);

  const getRichTextInputValue = (
    stepId: number,
    inputId: number,
    defaultValue: string
  ) => {
    const id = getInputId(stepId, inputId);

    if (workbookApiData && workbookApiData.steps) {
      const foundData =
        workbookApiData.steps[stepId - 1]?.inputItems?.find(
          (x) => x.sectionIdentifier === id
        )?.userInput ?? defaultValue;
      return foundData;
    }
    return defaultValue;
  };

  const getInputValue = (stepId: number, inputId: number) => {
    const id = getInputId(stepId, inputId);

    if (workbookApiData && workbookApiData.steps) {
      return (
        workbookApiData.steps[stepId - 1]?.inputItems?.find(
          (x) => x.sectionIdentifier === id
        )?.userInput ?? ""
      );
    }
    return "";
  };

  const getInputId = (stepId: number, inputId: number) => {
    return (
      "wb" + workbookId + "v" + version + "step" + stepId + "input" + inputId
    );
  };

  const updateRichTextValue = (str: string, id: any) => {
    updateAndSaveInputs(id.stepId, str, id.inputId);
  };

  const leftScroll = () => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scroll({
        top: 0,
        left: scrollDivRef.current.scrollLeft - 200,
        behavior: "smooth",
      });
    }
  };

  const rightScroll = () => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scroll({
        top: 0,
        left: scrollDivRef.current.scrollLeft + 200,
        behavior: "smooth",
      });
    }
  };

  const workbook: any = {
    title: "Service Manager Workbook",
    steps: [
      {
        stepId: 1,
        title: "Session 1:",
        subTitle: "Branding your Business",
        page: (
          <>
            <Form className="game-plan">
              <Container fluid className="p-0">
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 1)}>
                    <Form.Label>What Love Promise means to us:</Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 1) }}
                      data={getRichTextInputValue(1, 1, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 2)}>
                    <Form.Label>
                      How would a customer describe your store to a friend?:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 2) }}
                      data={getRichTextInputValue(1, 2, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 3)}>
                    <Form.Label>What we are known for:</Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 3) }}
                      data={getRichTextInputValue(1, 3, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <h3>Competition</h3>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 4)}>
                    <Form.Label>In our market, we compete with:</Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 4) }}
                      data={getRichTextInputValue(1, 4, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 5)}>
                    <Form.Label>
                      Their strengths and USP (Unique Selling Points):
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 5) }}
                      data={getRichTextInputValue(1, 5, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 6)}>
                    <Form.Label>
                      What sets us apart from our Competitors:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 6) }}
                      data={getRichTextInputValue(1, 6, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <h3>My Value Proposition</h3>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 7)}>
                    <Form.Label>
                      What I want people to know about my business:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 7) }}
                      data={getRichTextInputValue(1, 7, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 8)}>
                    <Form.Label>
                      What I tell people who ask why they should come to me for
                      Service:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 8) }}
                      data={getRichTextInputValue(1, 8, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <h3>My Vision for the Business</h3>
                <Form.Group as={Row} xs={12} controlId={getInputId(1, 9)}>
                  <Form.Label column md={6} lg={4}>
                    What we are known for
                  </Form.Label>
                  <Col md={6} lg={8}>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 9) }}
                      data={getRichTextInputValue(1, 9, "")}
                      update={updateRichTextValue}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} xs={12} controlId={getInputId(1, 10)}>
                  <Form.Label column md={6} lg={4}>
                    Customer Experience
                  </Form.Label>
                  <Col md={6} lg={8}>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 10) }}
                      data={getRichTextInputValue(1, 10, "")}
                      update={updateRichTextValue}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} xs={12} controlId={getInputId(1, 11)}>
                  <Form.Label column md={6} lg={4}>
                    Community
                  </Form.Label>
                  <Col md={6} lg={8}>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 11) }}
                      data={getRichTextInputValue(1, 11, "")}
                      update={updateRichTextValue}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} xs={12} controlId={getInputId(1, 12)}>
                  <Form.Label column md={6} lg={4}>
                    Our Team
                  </Form.Label>
                  <Col md={6} lg={8}>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 12) }}
                      data={getRichTextInputValue(1, 12, "")}
                      update={updateRichTextValue}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} xs={12} controlId={getInputId(1, 13)}>
                  <Form.Label column md={6} lg={4}>
                    Business Success &amp; Profits
                  </Form.Label>
                  <Col md={6} lg={8}>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 13) }}
                      data={getRichTextInputValue(1, 13, "")}
                      update={updateRichTextValue}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} xs={12} controlId={getInputId(1, 14)}>
                  <Form.Label column md={6} lg={4}>
                    Future Growth
                  </Form.Label>
                  <Col md={6} lg={8}>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 14) }}
                      data={getRichTextInputValue(1, 14, "")}
                      update={updateRichTextValue}
                    />
                  </Col>
                </Form.Group>
                <Form.Row className="my-4">
                  <h3>Competition</h3>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 15)}>
                    <Form.Label>
                      One paragraph summary of our Vision:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 15) }}
                      data={getRichTextInputValue(1, 15, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 16)}>
                    <Form.Label>
                      Areas we need to look at to reach our Vision:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 16) }}
                      data={getRichTextInputValue(1, 16, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <div ref={businessPlannerRef}></div>
                  <NavLink to="/business-plan" activeClassName="current">
                    <em>Update Business Planning Tool</em>
                  </NavLink>
                </Form.Row>
                <Form.Row className="mb-4">
                  <h3>Consultative Selling</h3>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 17)}>
                    <Form.Label>
                      What Selling should be for our Business:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 17) }}
                      data={getRichTextInputValue(1, 17, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 18)}>
                    <Form.Label>
                      Opportunity Areas in the Sales Process:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 18) }}
                      data={getRichTextInputValue(1, 18, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <h3>Quality Workmanship</h3>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 19)}>
                    <Form.Label>
                      What defines Quality for the customer?
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 19) }}
                      data={getRichTextInputValue(1, 19, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 20)}>
                    <Form.Label>
                      Steps we take to ensure Quality for our Customers:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 20) }}
                      data={getRichTextInputValue(1, 20, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(1, 21)}>
                    <Form.Label>
                      What to look for when reviewing Repair Orders:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 1, inputId: getInputId(1, 21) }}
                      data={getRichTextInputValue(1, 21, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <em>Update or Create Game Plan below</em>
                </Form.Row>
              </Container>
            </Form>
          </>
        ),
      },
      {
        stepId: 2,
        title: "Tweener 1:",
        subTitle: "Defining the Vision and Checking Some Numbers",
        page: (
          <>
            <Form className="game-plan">
              <Container fluid className="p-0">
                <Form.Row className="mb-4">
                  <Col>
                    <ol>
                      <li>
                        Update your overall Business Plan based on discussion
                        with team
                      </li>
                      <li>
                        Describe how you involved the team in developing your
                        Vision and Business Plan
                      </li>
                      <li>Create a Game Plan to ensure and sustain Quality</li>
                      <li>Pull selected Financial numbers for next session</li>
                      <li>
                        Be prepared to share one idea to improve profitability
                        in your operation
                      </li>
                    </ol>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(2, 1)}>
                    <Form.Label>
                      How we involved the team in developing our Business Plan:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 2, inputId: getInputId(2, 1) }}
                      data={getRichTextInputValue(2, 1, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(2, 2)}>
                    <Form.Label>
                      How we will make this plan successful:
                    </Form.Label>
                    <RichText
                      id={{ stepId: 2, inputId: getInputId(2, 2) }}
                      data={getRichTextInputValue(2, 2, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <h3>Financial Indicators</h3>
                <Form.Row className="mb-4">
                  <Col>
                    <Col md={5} className="float-left">
                      <Form.Group controlId={getInputId(2, 3)}>
                        <Form.Label>
                          Provide the following for the month of
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="June-20"
                          onBlur={(e: any) => updateInputValue(2, e)}
                          defaultValue={getInputValue(2, 3)}
                        />
                        <Form.Text>e.g. June-20</Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={5} className="float-left">
                      <Form.Group controlId={getInputId(2, 4)}>
                        <Form.Label>Monthly Flat Rate Hours</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="3456.3"
                          onBlur={(e: any) => updateInputValue(2, e)}
                          defaultValue={getInputValue(2, 4)}
                        />
                        <Form.Text>e.g. 3456.3</Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={5} className="float-left">
                      <Form.Group controlId={getInputId(2, 5)}>
                        <Form.Label>Effective Labor Rate</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="$78.21"
                          onBlur={(e: any) => updateInputValue(2, e)}
                          defaultValue={getInputValue(2, 5)}
                        />
                        <Form.Text>e.g. $78.21</Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={5} className="float-left">
                      <Form.Group controlId={getInputId(2, 6)}>
                        <Form.Label>Gross Profit Percentage</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="69.0%"
                          onBlur={(e: any) => updateInputValue(2, e)}
                          defaultValue={getInputValue(2, 6)}
                        />
                        <Form.Text>e.g. 69.0%</Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={5} className="float-left">
                      <Form.Group controlId={getInputId(2, 7)}>
                        <Form.Label>Personnel Expenses</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="$45,678"
                          onBlur={(e: any) => updateInputValue(2, e)}
                          defaultValue={getInputValue(2, 7)}
                        />
                        <Form.Text>e.g. $45,678</Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={5} className="float-left">
                      <Form.Group controlId={getInputId(2, 8)}>
                        <Form.Label>Operating Expenses</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="$34,567"
                          onBlur={(e: any) => updateInputValue(2, e)}
                          defaultValue={getInputValue(2, 8)}
                        />
                        <Form.Text>e.g. $34,567</Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={5} className="float-left">
                      <Form.Group controlId={getInputId(2, 9)}>
                        <Form.Label>Overhead Expenses</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="$23,456"
                          onBlur={(e: any) => updateInputValue(2, e)}
                          defaultValue={getInputValue(2, 99)}
                        />
                        <Form.Text>e.g. $23,456</Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={5} className="float-left">
                      <Form.Group controlId={getInputId(2, 10)}>
                        <Form.Label>Sublet Labor Gross Profit</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="$456"
                          onBlur={(e: any) => updateInputValue(2, e)}
                          defaultValue={getInputValue(2, 10)}
                        />
                        <Form.Text>e.g. $456</Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={5} className="float-left">
                      <Form.Group controlId={getInputId(2, 11)}>
                        <Form.Label>Other Non-Labor Gross Profit</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="$0"
                          onBlur={(e: any) => updateInputValue(2, e)}
                          defaultValue={getInputValue(2, 11)}
                        />
                        <Form.Text>e.g. $0</Form.Text>
                      </Form.Group>
                    </Col>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(2, 12)}>
                    <Form.Label>One idea to improve Profitability</Form.Label>
                    <RichText
                      id={{ stepId: 2, inputId: getInputId(2, 12) }}
                      data={getRichTextInputValue(2, 12, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
              </Container>
            </Form>
          </>
        ),
      },
      {
        stepId: 3,
        title: "Session 2:",
        subTitle: "Having an Impact on Operating Profit",
        page: (
          <>
            <Form className="game-plan">
              <Container fluid className="p-0">
                <Form.Row className="mb-4">
                  <Col>
                    <strong>Key terms</strong>
                    <br />
                    <br />
                    <ul>
                      <li>Sales: Flat Rate Hours x Effective Labor Rate</li>
                      <li>Gross Profit: Sales less cost of Flat Rate Hours</li>
                      <li>
                        Personnel Expenses: Non-Flat Rate Hour technician wages
                        and benefits
                      </li>
                      <li>
                        Operating Expenses: Expenses incurred through normal
                        operation
                      </li>
                      <li>
                        Overhead Expenses: Expenses to keep the doors open
                      </li>
                      <li>Operating Profit: Gross Profit less expenses</li>
                    </ul>
                  </Col>
                </Form.Row>
                <h3>Ideas to improve net profit</h3>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(3, 1)}>
                    <Form.Label>Production of Flat Rate Hours</Form.Label>
                    <RichText
                      id={{ stepId: 3, inputId: getInputId(3, 1) }}
                      data={getRichTextInputValue(
                        3,
                        1,
                        "<ol><li></li><li></li><li></li></ol>"
                      )}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(3, 2)}>
                    <Form.Label>Effective Labor Rate</Form.Label>
                    <RichText
                      id={{ stepId: 3, inputId: getInputId(3, 2) }}
                      data={getRichTextInputValue(
                        3,
                        2,
                        "<ol><li></li><li></li><li></li></ol>"
                      )}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(3, 3)}>
                    <Form.Label>Reduce Expenses</Form.Label>
                    <RichText
                      id={{ stepId: 3, inputId: getInputId(3, 3) }}
                      data={getRichTextInputValue(
                        3,
                        3,
                        "<ol><li></li><li></li><li></li></ol>"
                      )}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(3, 4)}>
                    <Form.Label>Manage Sublet</Form.Label>
                    <RichText
                      id={{ stepId: 3, inputId: getInputId(3, 4) }}
                      data={getRichTextInputValue(
                        3,
                        4,
                        "<ol><li></li><li></li><li></li></ol>"
                      )}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(3, 5)}>
                    <Form.Label>“What If” Scenarios: Pro-Forma</Form.Label>
                    <Col>
                      <p>
                        <a target="_blank" href="./files/Proforma_Tool.xlsx">
                          Pro Forma Tool
                        </a>
                      </p>
                    </Col>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(3, 6)}>
                    <Form.Label>
                      Top 3 opportunity areas (Highlight top choice)
                    </Form.Label>
                    <RichText
                      id={{ stepId: 3, inputId: getInputId(3, 6) }}
                      data={getRichTextInputValue(
                        3,
                        6,
                        "<ol><li></li><li></li><li></li></ol>"
                      )}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Col>
                    <Form.Label>Priority area for Game Plan</Form.Label>
                  </Col>
                  <Form.Group controlId={getInputId(3, 701)}>
                    <Form.Control
                      as="select"
                      value={getInputValue(3, 701)}
                      onChange={(e) => updateInputValue(3, e)}
                      className={"float-left"}
                    >
                      <option value="" disabled>
                        Impacts...
                      </option>
                      <option value="Flat Rate Hours">Flat Rate Hours</option>
                      <option value="Effective Labor Rate">
                        Effective Labor Rate
                      </option>
                      <option value="Expenses">Expenses</option>
                      <option value="Sublet">Sublet</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(3, 7)}>
                    <RichText
                      id={{ stepId: 3, inputId: getInputId(3, 7) }}
                      data={getRichTextInputValue(3, 7, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(3, 9)}>
                    <Form.Label>
                      What needs to happen to accomplish this?
                    </Form.Label>
                    <RichText
                      id={{ stepId: 3, inputId: getInputId(3, 9) }}
                      data={getRichTextInputValue(3, 9, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <em>Update Game Plan</em>
                <br />
                <br />
              </Container>
            </Form>
          </>
        ),
      },
      {
        stepId: 4,
        title: "Tweener 2:",
        subTitle: "Preparing for Customer Retention",
        page: (
          <>
            <Form className="game-plan">
              <Container fluid className="p-0">
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(4, 1)}>
                    <Form.Label>
                      What did you do to get started on your Financial Game
                      Plan?
                    </Form.Label>
                    <RichText
                      id={{ stepId: 4, inputId: getInputId(4, 1) }}
                      data={getRichTextInputValue(4, 1, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(4, 2)}>
                    <Form.Label>
                      The top 3 Opportunity Areas from the OLP reports
                    </Form.Label>
                    <RichText
                      id={{ stepId: 4, inputId: getInputId(4, 2) }}
                      data={getRichTextInputValue(
                        4,
                        2,
                        "<ol><li></li><li></li><li></li></ol>"
                      )}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(4, 3)}>
                    <Form.Label>
                      The top 3 Opportunity Areas from the perspective of the
                      team
                    </Form.Label>
                    <RichText
                      id={{ stepId: 4, inputId: getInputId(4, 3) }}
                      data={getRichTextInputValue(
                        4,
                        3,
                        "<ol><li></li><li></li><li></li></ol>"
                      )}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(4, 4)}>
                    <Form.Label>
                      One thing we do for customers that sets up apart
                    </Form.Label>
                    <RichText
                      id={{ stepId: 4, inputId: getInputId(4, 4) }}
                      data={getRichTextInputValue(4, 4, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(4, 5)}>
                    <Form.Label>
                      One thing we focus on to help improve customer retention
                    </Form.Label>
                    <RichText
                      id={{ stepId: 4, inputId: getInputId(4, 5) }}
                      data={getRichTextInputValue(4, 5, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
              </Container>
            </Form>
          </>
        ),
      },
      {
        stepId: 5,
        title: "Session 3:",
        subTitle: "Improving Customer Retention",
        page: (
          <>
            <Form className="game-plan">
              <Container fluid className="p-0">
                <h3>Customer Defection Points: How we address them</h3>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 1)}>
                    <Form.Label>Convenience</Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 1) }}
                      data={getRichTextInputValue(5, 1, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 2)}>
                    <Form.Label>Price vs Value Received</Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 2) }}
                      data={getRichTextInputValue(5, 2, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 3)}>
                    <Form.Label>Service Advisor Interaction</Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 3) }}
                      data={getRichTextInputValue(5, 3, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 4)}>
                    <Form.Label>Best Ideas from discussion</Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 4) }}
                      data={getRichTextInputValue(5, 4, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <h3>6 Ways to improve Customer Retention</h3>
                <Form.Row className="mb-4">
                  <Col>
                    <table className="workbookTable">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>
                            <p>1. Have Consistent Processes</p>
                            <p>2. Communicate with the Team</p>
                            <p>3. Meet Promise Times</p>
                          </td>
                          <td>
                            <p>4. Be Convenient</p>
                            <p>5. Add Perceived Value</p>
                            <p>6. Quality Workmanship</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Form.Row>

                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 6)}>
                    <Form.Label>
                      {
                        "1) Which process has the greatest opportunity for improvement?"
                      }
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 6) }}
                      data={getRichTextInputValue(5, 6, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 7)}>
                    <Form.Label>
                      {
                        "2) Two things I can do to have more effective meetings with the team"
                      }
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 7) }}
                      data={getRichTextInputValue(5, 7, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 8)}>
                    <Form.Label>
                      {
                        "3) Something I need to remind the team of regarding promise times"
                      }
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 8) }}
                      data={getRichTextInputValue(5, 8, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 9)}>
                    <Form.Label>
                      {
                        "4) What can you do to be more convenient for the customer?"
                      }
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 9) }}
                      data={getRichTextInputValue(5, 9, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col}>
                    <Form.Label>5) Adding Value</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 10)}>
                    <Form.Label>
                      Something we do to add more value for the customer
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 10) }}
                      data={getRichTextInputValue(5, 10, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 11)}>
                    <Form.Label>
                      How I would describe “Selling the Creative Effort” to my
                      team
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 11) }}
                      data={getRichTextInputValue(5, 11, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 12)}>
                    <Form.Label>
                      Something in the Consultative Sales process that we could
                      improve
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 12) }}
                      data={getRichTextInputValue(5, 12, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 13)}>
                    <Form.Label>
                      Best idea for providing options to the customer
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 13) }}
                      data={getRichTextInputValue(5, 13, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 14)}>
                    <Form.Label>
                      {"6) Best ideas for ensuring quality"}
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 14) }}
                      data={getRichTextInputValue(5, 14, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Col>
                    <Form.Label>
                      {
                        "Something that our store will work on to improve Customer Retention"
                      }
                    </Form.Label>
                  </Col>
                  <Form.Group controlId={getInputId(5, 1501)}>
                    <Form.Control
                      as="select"
                      value={getInputValue(5, 1501)}
                      onChange={(e) => updateInputValue(5, e)}
                      className={"float-left"}
                    >
                      <option value="" disabled>
                        Impacts...
                      </option>
                      <option value="Process">Process</option>
                      <option value="Communication">Communication</option>
                      <option value="Promise Times">Promise Times</option>
                      <option value="Convenience">Convenience</option>
                      <option value="Value">Value</option>
                      <option value="Quality">Quality</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 15)}>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 15) }}
                      data={getRichTextInputValue(5, 15, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 16)}>
                    <Form.Label>
                      {"First thoughts on what might help the situation"}
                    </Form.Label>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 16) }}
                      data={getRichTextInputValue(5, 16, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Col>
                    <Form.Label>
                      {"How  we will measure the impact of our efforts"}
                    </Form.Label>
                  </Col>
                  <Form.Group controlId={getInputId(5, 1701)}>
                    <Form.Control
                      as="select"
                      value={getInputValue(5, 1701)}
                      onChange={(e) => updateInputValue(5, e)}
                      className={"float-left"}
                    >
                      <option value="" disabled>
                        Measured By...
                      </option>
                      <option value="First Maintenance Complete">
                        First Maintenance Complete
                      </option>
                      <option value="Ongoing Service Retention">
                        Ongoing Service Retention
                      </option>
                      <option value="Net Promotor Score">
                        Net Promotor Score
                      </option>
                      <option value="Customer Satisfaction">
                        Customer Satisfaction
                      </option>
                      <option value="Key Satisfaction Indicators">
                        Key Satisfaction Indicators
                      </option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(5, 17)}>
                    <RichText
                      id={{ stepId: 5, inputId: getInputId(5, 17) }}
                      data={getRichTextInputValue(5, 17, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <em>Update Game Plan</em>
                <br />
                <br />
              </Container>
            </Form>
          </>
        ),
      },
      {
        stepId: 6,
        title: "Tweener 3:",
        subTitle: "Coming up with a Plan",
        page: (
          <>
            <Form className="game-plan">
              <Container fluid className="p-0">
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(6, 1)}>
                    <Form.Label>
                      How we got the team engaged around improving Customer
                      Retention
                    </Form.Label>
                    <RichText
                      id={{ stepId: 6, inputId: getInputId(6, 1) }}
                      data={getRichTextInputValue(6, 1, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(6, 2)}>
                    <Form.Label>What we did to make a change</Form.Label>
                    <RichText
                      id={{ stepId: 6, inputId: getInputId(6, 2) }}
                      data={getRichTextInputValue(6, 2, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(6, 3)}>
                    <Form.Label>
                      One thing that really works about our MPI process
                    </Form.Label>
                    <RichText
                      id={{ stepId: 6, inputId: getInputId(6, 3) }}
                      data={getRichTextInputValue(6, 3, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(6, 4)}>
                    <Form.Label>
                      One thing we want to know before this Session is complete
                    </Form.Label>
                    <RichText
                      id={{ stepId: 6, inputId: getInputId(6, 4) }}
                      data={getRichTextInputValue(6, 4, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(6, 5)}>
                    <Form.Label>
                      Biggest challenge we face in making changes to our
                      processes
                    </Form.Label>
                    <RichText
                      id={{ stepId: 6, inputId: getInputId(6, 5) }}
                      data={getRichTextInputValue(6, 5, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId={getInputId(6, 6)}>
                    <Form.Label>
                      How we overcome that challenge in our store
                    </Form.Label>
                    <RichText
                      id={{ stepId: 6, inputId: getInputId(6, 6) }}
                      data={getRichTextInputValue(6, 6, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col}>
                    <Form.Label>
                      Find these numbers for last month and YTD
                    </Form.Label>
                    <table className={"workbookTable"}>
                      <thead>
                        <th></th>
                        <th>MTD</th>
                        <th>YTD</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Hours per Repair Order</td>
                          <td>
                            <Form.Group as={Col} controlId={getInputId(6, 7)}>
                              <Form.Control
                                type="text"
                                onBlur={(e: any) => updateInputValue(6, e)}
                                defaultValue={getInputValue(6, 7)}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group as={Col} controlId={getInputId(6, 8)}>
                              <Form.Control
                                type="text"
                                onBlur={(e: any) => updateInputValue(6, e)}
                                defaultValue={getInputValue(6, 8)}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        <tr>
                          <td>Repair Orders per Advisor</td>
                          <td>
                            <Form.Group as={Col} controlId={getInputId(6, 9)}>
                              <Form.Control
                                type="text"
                                onBlur={(e: any) => updateInputValue(6, e)}
                                defaultValue={getInputValue(6, 9)}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group as={Col} controlId={getInputId(6, 10)}>
                              <Form.Control
                                type="text"
                                onBlur={(e: any) => updateInputValue(6, e)}
                                defaultValue={getInputValue(6, 10)}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Form.Group>
                  <br />
                  <br />
                </Form.Row>
              </Container>
            </Form>
          </>
        ),
      },
      {
        stepId: 7,
        title: "Session 4:",
        subTitle: "Key Performance Indicators and Process Improvement",
        page: (
          <>
            <Form className="game-plan">
              <Container fluid className="p-0">
                <h3>Key Performance Indicators</h3>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 1)}>
                    <Form.Label>
                      Top ways we measure performance of our Advisors
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 1) }}
                      data={getRichTextInputValue(7, 1, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 2)}>
                    <Form.Label>
                      Top ways we measure performance of our Technicians and
                      Shop
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 2) }}
                      data={getRichTextInputValue(7, 2, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 3)}>
                    <Form.Label>
                      What we can do to improve Hours per Repair Order
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 3) }}
                      data={getRichTextInputValue(7, 3, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 4)}>
                    <Form.Label>
                      How we maximize how many Repair Orders can be written each
                      day
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 4) }}
                      data={getRichTextInputValue(7, 4, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 5)}>
                    <Form.Label>
                      Steps we have taken to improve Effective Labor Rate
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 5) }}
                      data={getRichTextInputValue(7, 5, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 6)}>
                    <Form.Label>
                      How we evaluate menu, MPI and Additional Service Requests
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 6) }}
                      data={getRichTextInputValue(7, 6, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 7)}>
                    <Form.Label>
                      What is the biggest opportunity for improvement between
                      parts and service?
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 7) }}
                      data={getRichTextInputValue(7, 7, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 8)}>
                    <Form.Label>
                      Techniques to improve sales of defection parts
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 8) }}
                      data={getRichTextInputValue(7, 8, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <h3>Post Diagnostic Process</h3>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 9)}>
                    <Form.Label>
                      Ways to improve the Technician Process
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 9) }}
                      data={getRichTextInputValue(7, 9, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 10)}>
                    <Form.Label>Ways to improve the Advisor Process</Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 10) }}
                      data={getRichTextInputValue(7, 10, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 11)}>
                    <Form.Label>
                      How we know how well our store is performing in this
                      process
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 11) }}
                      data={getRichTextInputValue(7, 11, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <h3>Active Service Delivery</h3>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 12)}>
                    <Form.Label>
                      How we can improve this in our store
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 12) }}
                      data={getRichTextInputValue(7, 12, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <h3>
                  Process: A series of actions or steps taken in order to
                  achieve a particular end
                </h3>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Process Improvement Notes</Form.Label>
                    <table className={"workbookTable"}>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>Have a clear outcome</td>
                          <td>
                            <Form.Group as={Col} controlId={getInputId(7, 13)}>
                              <RichText
                                id={{ stepId: 7, inputId: getInputId(7, 13) }}
                                data={getRichTextInputValue(7, 13, "")}
                                update={updateRichTextValue}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        <tr>
                          <td>Create Ideal Process</td>
                          <td>
                            <Form.Group as={Col} controlId={getInputId(7, 14)}>
                              <RichText
                                id={{ stepId: 7, inputId: getInputId(7, 14) }}
                                data={getRichTextInputValue(7, 14, "")}
                                update={updateRichTextValue}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        <tr>
                          <td>Get feedback and Buy-in</td>
                          <td>
                            <Form.Group as={Col} controlId={getInputId(7, 15)}>
                              <RichText
                                id={{ stepId: 7, inputId: getInputId(7, 15) }}
                                data={getRichTextInputValue(7, 15, "")}
                                update={updateRichTextValue}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        <tr>
                          <td>Launch and Track</td>
                          <td>
                            <Form.Group as={Col} controlId={getInputId(7, 16)}>
                              <RichText
                                id={{ stepId: 7, inputId: getInputId(7, 16) }}
                                data={getRichTextInputValue(7, 16, "")}
                                update={updateRichTextValue}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Form.Group>
                </Form.Row>

                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 17)}>
                    <Form.Label>
                      Best ideas from Process Improvement discussion
                    </Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 17) }}
                      data={getRichTextInputValue(7, 17, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <Form.Group as={Col} xs={12} controlId={getInputId(7, 18)}>
                    <Form.Label>After this class, I will</Form.Label>
                    <RichText
                      id={{ stepId: 7, inputId: getInputId(7, 18) }}
                      data={getRichTextInputValue(7, 18, "")}
                      update={updateRichTextValue}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mb-4">
                  <em>Update Game Plans</em>
                </Form.Row>
              </Container>
            </Form>
          </>
        ),
      },
    ],
  };

  const updateInputValue = (stepId: number, e: any) => {
    updateAndSaveInputs(stepId, e.target.value, e.target.id);
  };

  const updateAndSaveInputs = (
    stepId: number,
    inputValue: string,
    inputId: string
  ) => {
    const stepsArray: WorkbookStepApiPatchData[] = [];
    const inputDataArray = [];
    inputDataArray.push({
      sectionIdentifier: inputId,
      userInput: inputValue,
    });
    stepsArray.push({ stepId: stepId, inputItems: inputDataArray });
    if (workbookApiData.steps[stepId - 1].stepStatus === "NOTSTARTED") {
      stepsArray[0].stepStatus = "INPROGRESS";
    }

    const patchData = { workbookId: parseInt(workbookId), steps: stepsArray };

    api
      .patch(patchData)
      .then((response) => {})
      .catch(console.error);

    const temp = {
      workbookId: workbookApiData.workbookId,
      workbookStatus: workbookApiData.workbookStatus,
      steps: Array.from(workbookApiData.steps ?? []),
      title: workbookApiData.title,
    };
    if (temp.steps[stepId - 1].stepStatus === "NOTSTARTED") {
      temp.steps[stepId - 1].stepStatus = "INPROGRESS";
    }

    const findInput = temp.steps[stepId - 1].inputItems?.find(
      (x) => x.sectionIdentifier === inputId
    );
    if (findInput) {
      temp.steps[stepId - 1].inputItems?.forEach((item) => {
        if (item.sectionIdentifier === inputId) {
          item.userInput = inputValue;
        }
      });
    } else {
      temp.steps[stepId - 1].inputItems?.push({
        sectionIdentifier: inputId,
        userInput: inputValue,
      });
    }
    setWorkbookApiData(temp);
  };

  const updateStepStatus = (stepId: number, status: string) => {
    const stepsArray = [];
    stepsArray.push({ stepId: stepId, stepStatus: status });
    const patchData = { workbookId: parseInt(workbookId), steps: stepsArray };
    api
      .patch(patchData)
      .then((response) => {})
      .catch(console.error);

    const temp = {
      workbookId: workbookApiData.workbookId,
      workbookStatus: workbookApiData.workbookStatus,
      steps: Array.from(workbookApiData.steps ?? []),
      title: workbookApiData.title,
    };
    temp.steps[stepId - 1].stepStatus = status;
    setWorkbookApiData(temp);

    if (getProgress() === 100) {
      api
        .patch({
          workbookId: parseInt(workbookId),
          workbookStatus: "COMPLETED",
        })
        .then((response) => {})
        .catch(console.error);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    /*const maxStepId = workbook.steps?.length ?? 0;
    if (stepId + 1 <= maxStepId) {
      setActiveTab("step-" + stepId); //0 indexed
    } else {
      setActiveTab("step-" + (maxStepId - 1));
    }*/
  };

  const resetAllStatus = (status: string) => {
    const stepsArray: WorkbookStepApiPatchData[] = [];
    workbookApiData.steps.forEach((_) => {
      stepsArray.push({ stepId: _.stepId, stepStatus: status });
    });

    const patchData = {
      workbookId: parseInt(workbookId),
      steps: stepsArray,
      workbookStatus: "NOTSTARTED",
    };
    api
      .patch(patchData)
      .then((response) => {})
      .catch(console.error);

    const temp = {
      workbookId: workbookApiData.workbookId,
      workbookStatus: workbookApiData.workbookStatus,
      steps: Array.from(workbookApiData.steps ?? []),
      title: workbookApiData.title,
    };
    temp.steps.forEach((_) => (_.stepStatus = status));
    setWorkbookApiData(temp);

    setActiveTab("step-0");
  };

  const getProgress = (): number =>
    Math.round(
      workbookApiData.steps.length > 0
        ? (workbookApiData?.steps
            ?.map((_: WorkbookStepApiData) =>
              _.stepStatus === "COMPLETED" ? 1 : 0
            )
            .reduce((a: any, b: any) => a + b) /
            workbookApiData.steps.length) *
            100
        : 0
    );

  const getColor = (percent: number): string => {
    let color: string;
    if (percent === 0) {
      color = "muted";
    } else if (percent === 100) {
      color = "success";
    } else {
      color = "warning";
    }

    return color;
  };

  const getIcon = (_: number): any => {
    const getStatus = workbookApiData?.steps[_ - 1]?.stepStatus ?? "NOTSTARTED";
    return getStatus === "NOTSTARTED"
      ? faCircle
      : getStatus === "INPROGRESS"
      ? faAdjust
      : faCheckCircle;
  };

  if (init === true) {
    return (
      <Container fluid className="page-workbook">
        <Row className="mb-4">
          <Col>
            <h1>{workbookApiData.title}</h1>
            <p>
              You can get started on the workbook right here or{" "}
              <a href="./files/VILT_Service-Mgr-Workbook.docx">
                download a blank workbook.
              </a>
            </p>
            <p>
              <Button
                className="ml-auto mt-3"
                onClick={() => resetAllStatus("NOTSTARTED")}
              >
                Reset Progress <FontAwesomeIcon icon={faAngleRight} />
              </Button>
            </p>
            <strong className={`text-${getColor(getProgress())}`}>
              {getProgress()}% Complete
            </strong>
            <ProgressBar
              now={getProgress()}
              variant={getColor(getProgress())}
            />
          </Col>
        </Row>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={activeTab}
          activeKey={activeTab}
        >
          <Row noGutters>
            <div style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}>
              <div style={{ width: "10%", display: "flex", margin: "auto" }}>
                <button onClick={leftScroll} className="buttonScroll">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              </div>
              <div style={{ width: "80%", display: "flex" }}>
                <Nav variant="tabs" ref={scrollDivRef}>
                  {workbook.steps.map((step: any, i: number) => (
                    <Nav.Item key={i} className="w-25 mb-2">
                      <Nav.Link
                        eventKey={`step-${i}`}
                        className="d-flex border h-100 p-2"
                        onClick={() => setActiveTab(`step-${i}`)}
                      >
                        <FontAwesomeIcon
                          icon={getIcon(step.stepId)}
                          size="sm"
                          className="mr-2 mt-1"
                        />
                        <span className="flex-grow-1">
                          <strong>{step.title}</strong>
                          <br />
                          <small>{step.subTitle}</small>
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  margin: "auto",
                }}
              >
                <button onClick={rightScroll} className="buttonScroll">
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </div>
            <Col xs={12} className="bg-white border">
              <Tab.Content className="p-4">
                {workbook.steps.map((step: any, i: number) => (
                  <Tab.Pane key={i} eventKey={`step-${i}`}>
                    <h2>{step.title}</h2>
                    {step.page}

                    <Button
                      className="ml-auto mt-3"
                      onClick={() => updateStepStatus(step.stepId, "COMPLETED")}
                    >
                      Complete and continue{" "}
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

        <Row>
          <Col>
            <GamePlanList className="mt-5" />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </>
    );
  }
}
