import { get, destroy, patch, post } from "./base";

import type { GamePlanData } from "../../components/pages/gameplan/GamePlan";

export const GamePlans = {
  get: (uuid: string) => get<GamePlanData>("/v1/actionplans/" + uuid),
  remove: (uuid: string) => destroy(`/v1/actionplans/${uuid}`),
  patch: (data: any) => patch("/v1/actionplans", data),
  post: (data: any) => post("/v1/actionplans", data),
};
