import React from "react";
import { Container, Row, Col, Card, ProgressBar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function Workbooks() {
  const workbooks: any = [
    {
      title: "Service Manager Performance VILT",
      desc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id ultrices nunc, sed viverra risus.",
      progress: 60,
    },
    {
      title: "Workbook Example 2",
      desc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id ultrices nunc, sed viverra risus.",
      progress: 0,
    },
    {
      title: "Workbook Example 3",
      desc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id ultrices nunc, sed viverra risus.",
      progress: 100,
    },
    {
      title: "Workbook Example 4",
      desc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id ultrices nunc, sed viverra risus.",
      progress: 100,
    },
  ];

  const getColor = (percent: number): string => {
    let color: string;
    if (percent === 0) {
      color = "muted";
    } else if (percent === 100) {
      color = "success";
    } else {
      color = "warning";
    }

    return color;
  };

  return (
    <Container fluid className="page-workbooks">
      <Row>
        <Col>
          <h1>Workbooks</h1>
          <p>
            Each Workshop has a separate Workbook. Select the course that you
            are working on.
          </p>
        </Col>
      </Row>
      <Row>
        {workbooks.map((itm: any, i: number) => (
          <Col xs={12} md={6} className="mb-4" key={i}>
            <LinkContainer to={`/workbooks/${i}`} style={{ cursor: "pointer" }}>
              <Card>
                <Card.Body>
                  <strong>{itm.title}</strong>
                  <p>{itm.desc}</p>
                  <div className="float-right w-50">
                    <strong className={`text-${getColor(itm.progress)}`}>
                      {itm.progress}% Complete
                    </strong>
                    <ProgressBar
                      now={itm.progress}
                      variant={getColor(itm.progress)}
                    />
                  </div>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
