import React, { useEffect, useState } from "react";

import { Accordion, Container, Col, Form, Button, Row } from "react-bootstrap";

import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { MetricAreaChart } from "./charts";

import * as metricApi from "../../services/api/PinnedMetrics";
import * as metricTypeApi from "../../services/api/MetricTypes";

import { LinkContainer } from "react-router-bootstrap";

export type MetricType = {
  id: number;
  isTitle: boolean;
  name: string;
};

type Metric = {
  type: MetricType | undefined;
  columns: MetricColumn[];
  collapsed: boolean;
  uuid: string;
  isPinned: boolean;
  gamePlanName?: string;
  uuidGamePlan?: string;
};

type MetricColumn = {
  value: number;
  uuid: string;
};

export type MetricParentColumnApiData = {
  uuid: string;
  name: string;
  value: string;
};

export type MetricApiData = {
  uuid: string;
  name: string;
  isPinned: boolean;
  metricTypeId: number;
  columns: MetricParentColumnApiData[];
  gamePlanName?: string;
  uuidGamePlan?: string;
};

export default function PinnedMetrics(props: any) {
  const [metricState, setMetricState] = useState<Metric[]>([]);

  useEffect(() => {
    metricTypeApi.MetricTypes.get()
      .then((typesResponse) => {
        metricApi.PinnedMetrics.get()
          .then((metricsResponse) => {
            let uuidFirstOne = "";
            try {
              if (metricsResponse.data.length > 0) {
                uuidFirstOne = metricsResponse.data[0].uuid;
              }
            } catch (error) {}
            setMetricState(
              metricsResponse.data.map((item) => {
                return {
                  type: typesResponse.data.find(
                    (_) => _.id === item.metricTypeId
                  ),
                  uuid: item.uuid,
                  columns: item.columns.map((column) => {
                    return {
                      value: parseInt(column.value),
                      uuid: column.uuid,
                    };
                  }),
                  collapsed: item.uuid === uuidFirstOne ? false : true,
                  isPinned: item.isPinned,
                  gamePlanName: item.gamePlanName,
                  uuidGamePlan: item.uuidGamePlan,
                };
              })
            );
          })
          .catch(console.error);
      })
      .catch(console.error);
  }, [setMetricState]);

  const submitValues = (e: any) => {
    console.log(e.target.value);
  };

  const toggleCollapse = (uuid: string) => {
    const arrayTemp = Array.from(metricState);
    setMetricState(
      arrayTemp.map((item) =>
        item.uuid === uuid
          ? { ...item, collapsed: item.collapsed === true ? false : true }
          : { ...item, collapsed: true }
      )
    );
  };

  return (
    <Container fluid className="p-0">
      <Accordion defaultActiveKey={"metric-0"}>
        {metricState.map((metric: Metric, i: number) => (
          <Row key={i}>
            <Col>
              <Form onSubmit={submitValues}>
                <Container fluid className="bg-white">
                  <Row className="bg-dark text-light py-2">
                    <Col xs={12}>
                      {metric.type?.name}
                      {" - "}
                      <LinkContainer to={`/game-plan/${metric.uuidGamePlan}`}>
                        <Button variant="link" className="d-inline">
                          {metric.gamePlanName}
                        </Button>
                      </LinkContainer>
                      <Accordion.Toggle
                        eventKey={"metric-" + i}
                        as={Button}
                        variant="link"
                        className="float-left p-0 mr-2"
                        data-toggle="button"
                        aria-pressed="false"
                        onClick={() => toggleCollapse(metric.uuid)}
                      >
                        <I
                          icon={metric.collapsed ? faChevronDown : faChevronUp}
                          fixedWidth
                        />
                      </Accordion.Toggle>
                    </Col>
                  </Row>
                  <Accordion.Collapse eventKey={"metric-" + i}>
                    <Row>
                      <Col xs={12}>
                        <MetricAreaChart
                          data={metricState[i].columns.map((item) => {
                            return item.value;
                          })}
                        />
                      </Col>
                    </Row>
                  </Accordion.Collapse>
                </Container>
              </Form>
            </Col>
          </Row>
        ))}
      </Accordion>
    </Container>
  );
}
