import React, { useCallback, useEffect, useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//import BasicStyle from "@ckeditor/ckeditor5-essentials";

import useCountDown from "react-countdown-hook";
import Moment from "react-moment";

const initialTime = 10 * 1000;
const interval = 1000;

export default function RichText({ data, update, id }) {
  const [editorText, setEditorText] = useState(data);
  const [saveTime, setSaveTime] = useState();

  const [timeLeft, { start, pause, reset }] = useCountDown(
    initialTime,
    interval
  );

  const onChange = (e, editor) => {
    setEditorText(editor.getData());
    reset();
    start();
  };

  const onBlur = (e) => {
    pause();
    save();
    setShowEditor(false);
  };

  const save = useCallback(() => {
    if (data !== editorText) {
      setSaveTime(Date.now());
      update(editorText, id);
    }
  }, [editorText, data, update, id]);

  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    if (timeLeft === 0) {
      save();
    }
  }, [timeLeft, save]);

  const getData = () => {
    if (data === "" || data === null || data === undefined) {
      return "<p>&nbsp;</p>";
    }
    return data;
  };

  return (
    <>
      {!showEditor && (
        <div
          className={"ck ck-editor__main"}
          onClick={() => {
            setShowEditor(true);
          }}
        >
          <div
            className={
              "ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline"
            }
            dangerouslySetInnerHTML={{ __html: getData() }}
          ></div>
        </div>
      )}
      {showEditor && (
        <CKEditor
          editor={ClassicEditor}
          data={data}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      {saveTime && (
        <small className="text-muted">
          Last saved at <Moment date={saveTime} format="h:mmA" />
        </small>
      )}
    </>
  );
}
