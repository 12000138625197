import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";

import { Button, Table, Spinner } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import InfoBadge from "../../elements/InfoBadge";
import AlertBadge from "../../elements/AlertBadge";
import ProgressMeter from "../../elements/ProgressMeter";

import * as gamePlanListApi from "../../../services/api/GamePlanList";
//import * as gamePlanApi from "../../../services/api/GamePlan";
import { v4 as uuid } from "uuid";

interface IActionPlanListItem {
  id: number;
  title: string;
  impacts: string;
  progress: number;
  alert: boolean;
  [key: string]: any;
}

interface IActionPlansColumn {
  id: string;
  title: string;
  align: string;
}

export type ActionPlanSummary = {
  uuid: string;
  projectName: string;
  status: string;
  impacts: string[];
  progress: number;
  hasAlert: boolean;
};

export default function GamePlanList(props: any) {
  const history = useHistory();
  const [sortBy, setSortBy] = useState({ id: "due", desc: false });
  const [actionPlans, setActionPlans] = useState<IActionPlanListItem[]>([]);
  const [actionPlansStatus, setActionPlansStatus] = useState("ACTIVE");
  const [init, setInit] = useState<boolean | null>(false);

  useEffect(() => {
    gamePlanListApi.GamePlanList.get("ACTIVE")
      .then((res: any) => {
        setInit(true);
        setActionPlans(
          res.data.map((item: ActionPlanSummary) => {
            return {
              key: item.uuid,
              title: item.projectName,
              id: 0,
              impacts: item.impacts.join(", "),
              progress: item.progress,
              alert: item.hasAlert,
            };
          })
        );
      })
      .catch(console.error);
  }, [setActionPlans, setInit]);

  const columns: IActionPlansColumn[] = [
    { id: "title", title: "Plan Name", align: "left" },
    { id: "impacts", title: "Impacts", align: "left" },
    { id: "progress", title: "Progress", align: "center" },
    { id: "alert", title: "Alert", align: "center" },
  ];

  /*
  const actionPlans: IActionPlanListItem[] = [
    {
      id: 0,
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac sapien auctor eros consectetur fermentum at et libero. Nulla vehicula dignissim purus, eu lacinia dui elementum in.",
      impacts: ["impact 1, impact 2"],
      progress: 25,
      alert: true,
    },
    {
      id: 0,
      title: "2Improving the use of MPI as a comm",
      impacts: ["BService Manager"],
      progress: 50,
      alert: false,
    },
    {
      id: 0,
      title: "3Improving the use of MPI as a comm",
      impacts: ["CService Manager"],
      progress: 75,
      alert: false,
    },
  ];
  */

  const handleSort = ({ id }: IActionPlansColumn) => {
    setSortBy({ id, desc: id === sortBy.id ? !sortBy.desc : sortBy.desc });
  };

  const statusHandler = (e: any) => {
    setActionPlansStatus(e.target.value);
    gamePlanListApi.GamePlanList.get(e.target.value)
      .then((res: any) => {
        setActionPlans(
          res.data.map((item: ActionPlanSummary) => {
            return {
              key: item.uuid,
              title: item.projectName,
              id: 0,
              impacts: item.impacts.join(", "),
              progress: item.progress,
              alert: item.hasAlert,
            };
          })
        );
      })
      .catch(console.error);
  };

  /*
  const createNewGamePlan = () => {
    const getUuid = uuid();
    gamePlanApi.GamePlans.post({
      uuid: getUuid,
      projectName: "New",
      status: "ACTIVE",
    })
      .then((response) => {
        if (response.statusText === "OK") {
          history.push("/game-plan/" + getUuid);
        }
      })
      .catch(console.error);
  };*/

  if (init === true) {
    return (
      <div {...props}>
        {!props.noTitle && (
          <h2>
            Game Plans{" "}
            <InfoBadge>
              Game Plans are the specific projects to help accomplish the
              Prioritized Areas
            </InfoBadge>
          </h2>
        )}

        <select
          className="form-control w-25 mb-3"
          value={actionPlansStatus}
          onChange={(e) => statusHandler(e)}
        >
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
          <option value="COMPLETED">Completed</option>
        </select>

        <Table bordered className="bg-white">
          <colgroup>
            <col />
            <col />
            <col width={10} />
            <col width={10} />
          </colgroup>
          <thead className="thead-light">
            <tr>
              {columns.map((_: any, i: number) => (
                <th
                  key={i}
                  className={`position-relative text-nowrap text-${_.align}`}
                >
                  <Button
                    variant="link"
                    onClick={() => handleSort(_)}
                    className="stretched-link text-dark"
                  >
                    <span className="mr-2">{_.title}</span>
                    {sortBy.id === _.id && (
                      <I
                        icon={sortBy.desc ? faCaretDown : faCaretUp}
                        className="text-muted"
                      />
                    )}
                  </Button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {actionPlans
              .sort(({ [sortBy.id]: a }, { [sortBy.id]: b }) => {
                let [A, B] = Array(2);
                let val: number = 0;

                switch (typeof a) {
                  case "boolean":
                    [A, B] = [a ? 1 : -1, b ? 1 : -1];
                    val = sortBy.desc ? B - A : A - B;
                    break;
                  case "string":
                    [A, B] = [a.toUpperCase(), b.toUpperCase()];
                    val = (A < B ? -1 : A > B ? 1 : 0) * (sortBy.desc ? -1 : 1);
                    break;
                  case "number":
                    val = sortBy.desc ? b - a : a - b;
                    break;
                }

                return val;
              })
              .map((_: any, i: number) => (
                <tr key={i}>
                  <td
                    className="text-left text-truncate"
                    style={{ maxWidth: "400px", verticalAlign: "middle" }}
                  >
                    <LinkContainer to={`/game-plan/${_.key}`}>
                      <Button variant="link" className="d-inline">
                        {_.title}
                      </Button>
                    </LinkContainer>
                  </td>
                  <td>{_.impacts}</td>
                  <td
                    className="text-center"
                    style={{ verticalAlign: "middle" }}
                  >
                    <ProgressMeter value={_.progress} />
                  </td>
                  <td
                    className="text-center"
                    style={{ verticalAlign: "middle" }}
                  >
                    {_.alert && (
                      <AlertBadge>
                        This Game Plan has an uncompleted task that is past due.
                      </AlertBadge>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div>
          {!actionPlans.length && <>You don't have any game plans yet.</>}{" "}
          <Button onClick={() => history.push("/game-plan/" + uuid())}>
            Create New Plan
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </>
    );
  }
}
