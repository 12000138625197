import React from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Popover } from "react-bootstrap";

function AlertBadge(props: any) {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover id={`tooltip-${Math.round(Math.random() * 1000)}}`}>
          <Popover.Content>{props.children}</Popover.Content>
        </Popover>
      }
    >
      <I icon={faExclamationTriangle} color="#f00" size="lg" />
    </OverlayTrigger>
  );
}

export default AlertBadge;
