import { get, destroy, post, patch } from "./base";

import type { ActionsData } from "../../components/pages/gameplan/Tasks";

export const Tasks = {
  get: (uuidActionPlan: string) =>
    get<ActionsData[]>("/v1/actionplan/" + uuidActionPlan + "/tasks"),
  remove: (uuid: string) => destroy(`/v1/tasks/${uuid}`),
  post: (uuidActionPlan: string, action: ActionsData) =>
    post("/v1/actionplan/" + uuidActionPlan + "/tasks", action),
  patch: (uuidActionPlan: string, actions: ActionsData[]) =>
    patch("/v1/actionplan/" + uuidActionPlan + "/tasks", actions),
};
