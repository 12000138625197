import React, { useCallback } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";

export default function ConfirmDelete({ data, defineClass, selection }) {
  const deleteItem = useCallback(
    (isConfirmed) => {
      selection(data, isConfirmed);
    },
    [selection, data]
  );

  const submit = () => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteItem(true),
        },
        {
          label: "No",
          onClick: () => deleteItem(false),
        },
      ],
    });
  };

  return (
    <Button variant="link" className={defineClass} onClick={() => submit()}>
      <I icon={faTrashAlt} />
    </Button>
  );
}
