import { get, patch } from "./base";

export type WorkbookApiData = {
  workbookId: number;
  title: string;
  workbookStatus: string;
  steps: WorkbookStepApiData[];
};

export type WorkbookStepApiData = {
  stepId: number;
  title: string;
  subTitle: string;
  stepStatus: string;
  page: string;
  inputItems: WorkbookInputApiData[];
};

export type WorkbookApiPatchData = {
  workbookId: number;
  workbookStatus?: string;
  steps?: WorkbookStepApiPatchData[];
};

export type WorkbookStepApiPatchData = {
  stepId: number;
  stepStatus?: string;
  inputItems?: WorkbookInputApiData[];
};

export type WorkbookInputApiData = {
  sectionIdentifier: string;
  userInput: string;
};

export const Workbook = {
  get: (id: number) => get<WorkbookApiData>("/v1/workbook/" + id),
  patch: (data: WorkbookApiPatchData) => patch("/v1/workbook", data),
};
