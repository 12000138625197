import React, { FunctionComponent, useState } from "react";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Form, Spinner, Alert, Col } from "react-bootstrap";

export interface IProfile {
  status: ProfileStatus;
  mode: ProfileMode;
  user: ProfileUser;
  jobTitles: JobTitles;
  titleStatus: boolean;
  onUpdate: any;
}

export enum ProfileMode {
  CREATE,
  EDIT,
}

export enum ProfileStatus {
  HIDE,
  NORMAL,
  SAVING,
  FAILED,
  SUCCESS,
}

export type ProfileUser = {
  subaruUserID: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitleId: number;
  retailer: string;
  district: string;
  zone: string;
  region: string;
};

export type JobTitle = {
  id: number;
  name: string;
};

export type JobTitles = JobTitle[];

const Profile: FunctionComponent<IProfile> = (props: IProfile) => {
  const [user, setUser] = useState<ProfileUser>(props.user);
  const [validated, setValidated] = useState<boolean>(false);

  const fieldChangeHandler = (e: any) => {
    const { id, value } = e.target;
    if (user) {
      setUser({ ...user, [id]: value });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);
    props.onUpdate({ ...props, user });
  };

  return (
    <Modal
      show={props.status !== ProfileStatus.HIDE}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      onHide={() => props.onUpdate({ ...props, status: ProfileStatus.HIDE })}
    >
      <Form validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton={props.mode === ProfileMode.EDIT}>
          <Modal.Title>
            {props.mode === ProfileMode.CREATE ? (
              <span>Welcome!</span>
            ) : (
              <span>Edit Profile</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!props.user || !props.jobTitles.length ? (
            !props.titleStatus ? (
              <div className="text-center my-5">
                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                Failed to retrieve job titles, try again in a bit. <br />
                <a href=".">Refresh page.</a>
              </div>
            ) : (
              <div className="text-center my-5">
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="mr-2"
                />
                Loading profile
              </div>
            )
          ) : (
            <>
              {props.mode === ProfileMode.CREATE ? (
                <p>
                  Before we get started tell us more about yourself by filling
                  out the fields below.
                </p>
              ) : (
                <p>Update your profile here anytime.</p>
              )}
              <Form.Row>
                <Col>
                  <Form.Group controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      required
                      placeholder="John"
                      defaultValue={user.firstName}
                      onChange={fieldChangeHandler}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      required
                      placeholder="Doe"
                      defaultValue={user.lastName}
                      onChange={fieldChangeHandler}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group controlId="email">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="user@domain.com"
                      defaultValue={user.email}
                      onChange={fieldChangeHandler}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="jobTitleId">
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      defaultValue={user.jobTitleId > 0 ? user.jobTitleId : ""}
                      onChange={fieldChangeHandler}
                    >
                      <option disabled value="">
                        -- Select an Option --
                      </option>
                      {props.jobTitles
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((_: JobTitle, i: number) => (
                          <option key={_.id} value={_.id}>
                            {_.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group controlId="retailer">
                    <Form.Label>Retailer</Form.Label>
                    <Form.Control
                      required
                      placeholder="Doe"
                      defaultValue={user.retailer}
                      onChange={fieldChangeHandler}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="district">
                    <Form.Label>District</Form.Label>
                    <Form.Control
                      required
                      placeholder="Doe"
                      defaultValue={user.district}
                      onChange={fieldChangeHandler}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="zone">
                    <Form.Label>Zone</Form.Label>
                    <Form.Control
                      required
                      placeholder="Doe"
                      defaultValue={user.zone}
                      onChange={fieldChangeHandler}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="region">
                    <Form.Label>Region</Form.Label>
                    <Form.Control
                      required
                      placeholder="Doe"
                      defaultValue={user.region}
                      onChange={fieldChangeHandler}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div style={{ flex: 2 }}>
            <Alert
              show={props.status === ProfileStatus.FAILED}
              variant="warning"
              dismissible
              onClose={() =>
                props.onUpdate({
                  ...props,
                  status: ProfileStatus.NORMAL,
                })
              }
            >
              <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
              Save failed. Try again in a bit.
            </Alert>
            <Alert
              show={props.status === ProfileStatus.SUCCESS}
              variant="success"
            >
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              Save successful!
            </Alert>
          </div>
          <div>
            <Spinner
              hidden={props.status !== ProfileStatus.SAVING}
              animation="border"
              role="status"
              size="sm"
              className="mr-2"
            >
              <span className="sr-only"> Saving...</span>
            </Spinner>
            <Button
              hidden={props.mode !== ProfileMode.EDIT}
              variant="outline-primary"
              className="mr-2"
              onClick={() =>
                props.onUpdate({ ...props, status: ProfileStatus.HIDE })
              }
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                !props.user ||
                !props.jobTitles.length ||
                props.status === ProfileStatus.SAVING ||
                props.status === ProfileStatus.SUCCESS
              }
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Profile;
