import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";

import {
  Container,
  Col,
  Form,
  Button,
  Badge,
  Modal,
  Spinner,
} from "react-bootstrap";

import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";
import { faArchive, faTimes, faPen } from "@fortawesome/free-solid-svg-icons";
import {
  faPaperPlane,
  faSave,
  faFilePdf,
} from "@fortawesome/free-regular-svg-icons";

import InfoBadge from "../../elements/InfoBadge";
import Metrics from "../../elements/Metrics";
import Tasks from "./Tasks";

import * as api from "../../../services/api/GamePlan";
import * as emailApi from "../../../services/api/Email";

import { useParams } from "react-router-dom";
import moment from "moment";
import { atom, useRecoilState } from "recoil";

import RichText from "../../elements/RichText";

export type GamePlanData = {
  uuid: string;
  projectName: string;
  projectGoal: string;
  planDescription: string;
  otherImpactProgress: string;
  comments: string;
  isQuality: boolean;
  isProductionEfficiency: boolean;
  isCustomerExperience: boolean;
  isProfitability: boolean;
  sharedEmails: string[];
  relatedCourse: string;
  status: string;
  successes: string;
  nextSteps: string;
  improvements: string;
};

const getLastSaved = atom<string>({
  key: "getLastSaved",
  default: "",
});

const getDisabledSaveButton = atom<boolean>({
  key: "getDisabledSaveButton",
  default: true,
});

const getHiddenSpinner = atom<boolean>({
  key: "getHiddenSpinner",
  default: true,
});

export default function GamePlan(props: any) {
  const [lessonsLearned, setLessonsLearned] = useState({ show: false });
  const toggleLessonsLearned = () => {
    setLessonsLearned({ ...lessonsLearned, show: !lessonsLearned.show });
  };

  const saveLessionsLearned = () => {
    api.GamePlans.patch({
      uuid: uuidActionPlan,
      status: status,
      successes: successes,
      improvements: adjustments,
      nextSteps: nextSteps,
    })
      .then((res) => {
        if (res.statusText === "OK") {
          updateSaveButtonAfterSuccess();
        }
      })
      .catch(console.error);

    setLessonsLearned({ ...lessonsLearned, show: !lessonsLearned.show });
  };

  const { uuidActionPlan } = useParams<{ uuidActionPlan: string }>(); //"EE0770D4-EC8F-4212-9406-F692256169C2";

  const [init, setInit] = useState<boolean | null>(false);

  const [saveText, setSaveText] = useRecoilState(getLastSaved);
  const [disableSaveButton, setDisableSaveButton] = useRecoilState(
    getDisabledSaveButton
  );
  const [hideSpinner, setHideSpinner] = useRecoilState(getHiddenSpinner); //for initial loading
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showProgressSpinner, setShowProgressSpinner] = useState(true);
  const [progressResult, setProgressResult] = useState("Done!");

  const [projectName, setProjectName] = useState<string>("");
  const [projectGoal, setProjectGoal] = useState<string>("");
  const [planDescription, setPlanDescription] = useState<string>("");
  const [otherImpactProgress, setOtherImpactProgress] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  const [isCustomerExperience, setIsCustomerExperience] = useState(false);
  const [isQuality, setIsQuality] = useState(false);
  const [isProfitability, setIsProfitability] = useState(false);
  const [isProductionEfficiency, setIsProductionEfficiency] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [inputEmail, setInputEmail] = useState("");
  const [relatedWorkbook, setRelatedWorkbook] = useState("");

  //lessions learned
  const [status, setStatus] = useState("");
  const [nextSteps, setNextSteps] = useState("");
  const [successes, setSuccesses] = useState("");
  const [adjustments, setAdjustments] = useState("");

  //const printAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (init === false) {
      api.GamePlans.get(uuidActionPlan)
        .then((response) => {
          setIsQuality(response.data.isQuality);
          setIsCustomerExperience(response.data.isCustomerExperience);
          setIsProductionEfficiency(response.data.isProductionEfficiency);
          setIsProfitability(response.data.isProfitability);
          setEmailList(response.data.sharedEmails);
          setRelatedWorkbook(response.data.relatedCourse);
          setNextSteps(response.data.nextSteps);
          setAdjustments(response.data.improvements);
          setSuccesses(response.data.successes);
          setStatus(response.data.status);

          setProjectName(response.data.projectName);
          setProjectGoal(response.data.projectGoal);
          setComments(response.data.comments);
          setOtherImpactProgress(response.data.otherImpactProgress);
          setPlanDescription(response.data.planDescription);

          setInit(true);
        })
        .catch(() => {
          setInit(false);
        });
    }
  }, [init, uuidActionPlan]);

  const updateEmails = (data: string[]) =>
    api.GamePlans.patch({
      sharedEmails: data,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });

  const updateProjectName = (data: string) => {
    setProjectName(data);
    api.GamePlans.patch({
      projectName: data,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const updateProjectGoal = (data: string) => {
    setProjectGoal(data);
    api.GamePlans.patch({
      projectGoal: data,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const updateOtherImpactProgress = (data: string) => {
    setOtherImpactProgress(data);
    api.GamePlans.patch({
      otherImpactProgress: data,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const updatePlanDescription = (data: string) => {
    setPlanDescription(data);
    api.GamePlans.patch({
      planDescription: data,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const updateComments = (data: string) => {
    setComments(data);
    api.GamePlans.patch({
      comments: data,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const updateRelatedWorkbook = (e: any) => {
    activeSaveButton();
    api.GamePlans.patch({
      uuid: uuidActionPlan,
      relatedCourse: e.target.value,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const isQualityHandler = (e: any) => {
    activeSaveButton();
    setIsQuality(e.target.checked);
    api.GamePlans.patch({
      isQuality: e.target.checked,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const isProductionEfficiencyHandler = (e: any) => {
    activeSaveButton();
    setIsProductionEfficiency(e.target.checked);
    api.GamePlans.patch({
      isProductionEfficiency: e.target.checked,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const isProfitabilityHandler = (e: any) => {
    activeSaveButton();
    setIsProfitability(e.target.checked);
    api.GamePlans.patch({
      isProfitability: e.target.checked,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const isCustomerExperienceHandler = (e: any) => {
    activeSaveButton();
    setIsCustomerExperience(e.target.checked);
    api.GamePlans.patch({
      isCustomerExperience: e.target.checked,
      uuid: uuidActionPlan,
    }).then((res) => {
      if (res.statusText === "OK") {
        updateSaveButtonAfterSuccess();
      }
    });
  };

  const addEmailHandler = (e: any) => {
    //enter key
    if (e.key === "Enter") {
      activeSaveButton();
      const tempArray = emailList;
      tempArray.push(inputEmail);
      setEmailList(tempArray);
      updateEmails(tempArray);
      setInputEmail("");
    }
  };

  function deleteEmail(email: string) {
    activeSaveButton();
    const tempEmails = emailList.filter((x) => x !== email);
    setEmailList(tempEmails);
    console.log(tempEmails);
    updateEmails(tempEmails);
  }

  /*
  const fieldChangeHandler = (e: any) => {
    console.log(e);
  };*/

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
  };

  const updateSaveButtonAfterSuccess = () => {
    setHideSpinner(true);
    setSaveText(
      "Last saved at " + moment(new Date().toLocaleString()).format("h:mm A")
    );
    setDisableSaveButton(true);
  };

  const activeSaveButton = () => {
    setSaveText("");
    setDisableSaveButton(false);
  };

  const createEmail = () => {
    setShowProgressSpinner(true);
    setProgressResult("");
    setShowProgressModal(true);

    domtoimage
      .toJpeg(document.documentElement)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        var pdf = new jsPDF("p", "pt");
        var pdfWidth = pdf.internal.pageSize.getWidth();
        var pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
        const getBinary = pdf.output();

        setProgressResult("Email sent!");
        setShowProgressSpinner(false);

        emailApi.Email.post(uuidActionPlan, {
          base64String: btoa(getBinary),
        })
          .then((response) => {
            if (response.statusText === "OK") {
              setProgressResult("Email sent!");
            } else {
              setProgressResult("Error occurred.");
            }
            setShowProgressSpinner(false);
          })
          .catch(() => {
            setProgressResult("Error occurred.");
            setShowProgressSpinner(false);
          });
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
        setProgressResult("Error occurred.");
        setShowProgressSpinner(false);
      });
  };

  const sendEmail = () => {
    createEmail();
  };

  const getPdf = () => {
    printDocument();
  };

  const savePlan = (e: any) => {
    setDisableSaveButton(true);
    setHideSpinner(false);
  };

  const printDocument = () => {
    domtoimage
      .toJpeg(document.documentElement)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        var pdf = new jsPDF("p", "pt");
        var pdfWidth = pdf.internal.pageSize.getWidth();
        var pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("MyGamePlan.pdf");
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  if (init === true) {
    return (
      <>
        <Form className="game-plan" onSubmit={onSubmitHandler}>
          <Container fluid>
            <Form.Row>
              <Col>
                <h1>Game Plan</h1>
              </Col>
              <Form.Group
                as={Col}
                xs={4}
                controlId="formGridState"
                className="float-right"
              >
                <Form.Control
                  as="select"
                  value={relatedWorkbook}
                  onChange={(e) => updateRelatedWorkbook(e)}
                >
                  <option value="">Choose Related Workbook...</option>
                  <option value="Service Manager Performance VILT">
                    Service Manager Performance VILT
                  </option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="projectName">
                <Form.Label>
                  Plan Name{" "}
                  <InfoBadge>
                    The name of your plan. Use a name that will help you
                    recognize the plan at-a-glance.
                  </InfoBadge>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="flex-grow-1"
                  value={projectName}
                  onChange={(e: any) => {
                    activeSaveButton();
                    setProjectName(e.target.value);
                  }}
                  onBlur={(e: any) => updateProjectName(e.target.value)}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="status">
                <Form.Label>
                  Project Goal{" "}
                  <InfoBadge>
                    <u>A good goal is:</u>
                    <br />
                    <span className="font-weight-bolder">S</span>pecific
                    <br />
                    <span className="font-weight-bolder">M</span>easurable
                    <br />
                    <span className="font-weight-bolder">A</span>ctionable
                    <br />
                    <span className="font-weight-bolder">R</span>ealistic
                    <br />
                    <span className="font-weight-bolder">T</span>ime-bound
                  </InfoBadge>
                </Form.Label>
                <RichText
                  id="projectGoal"
                  data={projectGoal}
                  update={updateProjectGoal}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="status">
                <Form.Label>
                  Impacts{" "}
                  <InfoBadge>
                    Assigning impact area(s) ensures the plan aligns with your
                    Prioritized Areas and is used in reporting.
                  </InfoBadge>
                </Form.Label>
                <br />
                <Form.Check
                  type="checkbox"
                  inline
                  label="Customer Experience"
                  checked={isCustomerExperience}
                  onChange={isCustomerExperienceHandler}
                  contentEditable={false}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  label="Production/Efficiency"
                  checked={isProductionEfficiency}
                  onChange={isProductionEfficiencyHandler}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  label="Profitability"
                  checked={isProfitability}
                  onChange={isProfitabilityHandler}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  label="Quality"
                  checked={isQuality}
                  onChange={isQualityHandler}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="status">
                <Form.Label>
                  Plan Description{" "}
                  <InfoBadge>
                    Plan Description is a brief summary of how this Game Plan
                    will achieve the Project Goal.
                  </InfoBadge>
                </Form.Label>
                <RichText
                  id="planDescription"
                  data={planDescription}
                  update={updatePlanDescription}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className="mb-4">
              <Tasks />
            </Form.Row>
            <Form.Row className="mb-4">
              <Col>
                <Metrics editable />
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>
                  Other Impact and Progress:{" "}
                  <InfoBadge>
                    This is to document impacts that were not specifically
                    measured. Can be used to reflect progress or other results
                    that were not originally planned.
                  </InfoBadge>
                </Form.Label>
                <RichText
                  id="otherImpactProgress"
                  data={otherImpactProgress}
                  update={updateOtherImpactProgress}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>
                  Comments:{" "}
                  <InfoBadge>
                    Notes about the implementation to keep track of any
                    challenges or observations as the changes take place.
                  </InfoBadge>
                </Form.Label>
                <RichText
                  id="comments"
                  data={comments}
                  update={updateComments}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="formGridState">
                <Form.Label>
                  Share Action Plan:{" "}
                  <InfoBadge>
                    Include email address for anyone who will be reviewing this
                    plan.
                  </InfoBadge>
                </Form.Label>
                <div className="bg-white border p-2 mb-2">
                  {emailList.map((_) => (
                    <Badge pill variant="info" className="mr-2">
                      {_} &nbsp;{" "}
                      <I icon={faTimes} onClick={() => deleteEmail(_)} />
                    </Badge>
                  ))}
                  <Form.Control
                    type="email"
                    plaintext
                    placeholder="Add email..."
                    onKeyPress={addEmailHandler}
                    onChange={(e) => setInputEmail(e.target.value)}
                    value={inputEmail}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col xs={6}>
                <Button className="ml-2" onClick={() => getPdf()}>
                  <I icon={faFilePdf} /> Download
                </Button>
                <Button
                  className="ml-2"
                  onClick={() => sendEmail()}
                  disabled={emailList.length === 0}
                >
                  <I icon={faPaperPlane} /> Send
                </Button>
                <Modal show={showProgressModal} centered>
                  <Modal.Body>
                    <Container fluid>
                      <Spinner
                        animation="border"
                        role="status"
                        hidden={showProgressSpinner === false}
                      >
                        <span className="sr-only">Working...</span>
                      </Spinner>
                      <span hidden={showProgressSpinner}>{progressResult}</span>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setShowProgressModal(false)}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>

              <Col xs={6} className="text-right">
                <span className="text-muted">{saveText}</span>{" "}
                <Spinner
                  animation="border"
                  role="status"
                  hidden={hideSpinner}
                  size={"sm"}
                >
                  <span className="sr-only">Saving...</span>
                </Spinner>
                <Button
                  className="ml-2"
                  disabled={disableSaveButton}
                  onClick={(e: any) => savePlan(e)}
                >
                  <I icon={faSave} /> Save
                </Button>
                <Button className="ml-2" onClick={toggleLessonsLearned}>
                  <I icon={status === "ACTIVE" ? faArchive : faPen} />
                  {status === "ACTIVE" ? " Archive" : " Lessons Learned"}
                </Button>
              </Col>
            </Form.Row>
          </Container>
        </Form>
        <Modal
          show={lessonsLearned.show}
          onHide={toggleLessonsLearned}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>Lessons Learned</Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="status">
                  <Form.Label>
                    Status{" "}
                    <InfoBadge>
                      Status is Active by default, Inactive for paused plans,
                      and Completed for completed plans. Plans that are
                      Completed or Inactive are archived under “Lessons Learned”
                    </InfoBadge>
                  </Form.Label>
                  <br />
                  {["Active", "Inactive", "Completed"].map((_) => (
                    <Form.Check
                      type="checkbox"
                      inline
                      label={_}
                      onChange={() => setStatus(_.toUpperCase())}
                      checked={_.toUpperCase() === status}
                    />
                  ))}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>
                    Summary of what worked:{" "}
                    <InfoBadge>
                      Describe one thing that really worked as a result of this
                      plan. Can include multiple examples if needed
                    </InfoBadge>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    onChange={(e) => setSuccesses(e.target.value)}
                    defaultValue={successes}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>
                    Adjustments needed:{" "}
                    <InfoBadge>
                      What would you do differently the next time you need to
                      look at this topic?
                    </InfoBadge>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    onChange={(e) => setAdjustments(e.target.value)}
                    defaultValue={adjustments}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>
                    Next Steps:{" "}
                    <InfoBadge>
                      Describe any areas that still need to change after
                      completion of this project.
                    </InfoBadge>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    onChange={(e) => {
                      setNextSteps(e.target.value);
                    }}
                    defaultValue={nextSteps}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={toggleLessonsLearned}>
              Cancel
            </Button>
            <Button onClick={saveLessionsLearned}>Save</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </>
    );
  }
}
