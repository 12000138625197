import React, { useEffect, useState } from "react";

import { v4 as uuid } from "uuid";

import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";
//import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";

import { Button, ListGroup, Form, Col, Spinner } from "react-bootstrap";

import ConfirmDelete from "../../elements/ConfirmDelete";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import InfoBadge from "../../elements/InfoBadge";
import { atom, useRecoilState } from "recoil";

import * as api from "../../../services/api/Priorities";

export type Priority = {
  uuid: string;
  weight: number;
  text: string;
};

const getPriorities = atom<Priority[]>({
  key: "getPriorities",
  default: [],
});

// const prioritiesState = selector<Priority[]>({
//   key: "prioritiesState",
//   get: ({ get }) => get(getPriorities),
// });

const orderByWeight = (list: Priority[]): Priority[] =>
  Array.from(list).sort((a: Priority, b: Priority) => a.weight - b.weight);

export default function PrioritizedAreas() {
  //-- States --//
  const [priorities, setPriorities] = useRecoilState(getPriorities);
  const [editList, setEditList] = useState(
    priorities.reduce((map: any, obj: Priority) => {
      map[obj.uuid] = false;
      return map;
    }, new Map())
  );
  const [newValue, setNewValue] = useState("");
  const [init, setInit] = useState<boolean | null>(false);

  //-- Event Listeners --//
  useEffect(() => {
    api.Priorities.get()
      .then((res: any) => {
        setInit(true);
        setPriorities(orderByWeight(res.data));
      })
      .catch(console.error);
  }, [setPriorities, setInit]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    let tempPriorities = Array.from(priorities);
    const [removed] = tempPriorities.splice(result.source.index, 1);
    tempPriorities.splice(result.destination.index, 0, removed);
    tempPriorities = tempPriorities.map((_, i) => ({ ..._, weight: i }));
    setPriorities(orderByWeight(tempPriorities));

    api.Priorities.patch(tempPriorities)
      .then((res: any) => {
        if (!res.data.success) {
          setPriorities(orderByWeight(priorities));
          console.error("Could not save");
        }
      })
      .catch(console.error);
  };

  const onTextSubmit = (e: any, priority: Priority) => {
    e.preventDefault();
    e.target[0].blur();
  };

  const onTextBlur = (e: any, priority: Priority) => {
    if (e.target.value === "") {
      return onDelete(priority);
    }

    const tempPriority: Priority = { ...priority, text: e.target.value };

    api.Priorities.patch([tempPriority])
      .then((res: any) => {
        if (res.data.success) {
          priority = tempPriority;
          setEditList({ ...editList, [priority.uuid]: false });
          setPriorities([...priorities]);
        } else {
          e.target.focus();
          console.error("Could not save");
        }
      })
      .catch(console.error);
  };

  const onAdd = (e: any) => {
    e.preventDefault();

    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation();
    }
    const tempPriorities = Array.from(priorities);
    const newPriority: Priority = {
      uuid: uuid(),
      weight:
        Math.max.apply(
          Math,
          priorities.map((_) => _.weight)
        ) + 1,
      text: newValue,
    };
    tempPriorities.push(newPriority);

    api.Priorities.post(newPriority)
      .then((res: any) => {
        if (res.data.success) {
          setPriorities(tempPriorities);
          setNewValue("");
        } else {
          console.error("Could not save");
        }
      })
      .catch(console.error);
  };

  const onDelete = (priority: Priority) => {
    api.Priorities.remove(priority.uuid)
      .then((res: any) => {
        if (res.data.success) {
          const tempPriorities = priorities.filter(
            (_: Priority) => _.uuid !== priority.uuid
          );

          setPriorities(tempPriorities);
        } else {
          console.error("Could not save");
        }
      })
      .catch(console.error);
  };

  const confirmDelete = async (data: any, canDelete: boolean) => {
    if (canDelete === true) {
      onDelete(data);
    }
  };

  if (init === true) {
    return (
      <>
        <h2>
          Prioritized Areas{" "}
          <InfoBadge>
            Prioritized Areas are to make sure that the important topics are
            addressed first
          </InfoBadge>
        </h2>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="prioritiesDrop">
            {(provided, snapshot) => (
              <ListGroup
                {...provided.droppableProps}
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
              >
                {priorities.map((_: Priority, i: number) => (
                  <Draggable
                    key={`drag-${_.uuid}`}
                    draggableId={`drag-${_.uuid}`}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <ListGroup.Item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={provided.draggableProps.style}
                        className={`d-flex ${
                          snapshot.isDragging ? "dragging" : ""
                        }`}
                      >
                        <Button
                          variant="link"
                          className="handle"
                          style={{ marginLeft: -14 }}
                          {...provided.dragHandleProps}
                        >
                          <I icon={faGripVertical} size="sm" fixedWidth />
                        </Button>
                        <div
                          className="flex-fill pl-3"
                          onClick={() =>
                            setEditList({ ...editList, [_.uuid]: true })
                          }
                        >
                          <Form onSubmit={(e) => onTextSubmit(e, _)}>
                            <Form.Control
                              id={`priority-${_.uuid}`}
                              required
                              placeholder="Name this priority"
                              defaultValue={_.text}
                              // onChange={fieldChangeHandler}
                              onBlur={(e: any) => onTextBlur(e, _)}
                              data-priority={_}
                              plaintext={!editList[_.uuid]}
                            />
                          </Form>
                        </div>
                        <ConfirmDelete
                          defineClass="float-right p-0"
                          data={_}
                          selection={confirmDelete}
                        />
                      </ListGroup.Item>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <ListGroup.Item>
                  <Form onSubmit={onAdd}>
                    <Form.Row className="align-items-center">
                      <Col xs={{ span: true }}>
                        <Form.Control
                          required
                          id="new"
                          placeholder="New priority item"
                          value={newValue}
                          onChange={(e: any) => setNewValue(e.target.value)}
                        />
                      </Col>
                      <Col xs="auto">
                        <Button type="submit">Save</Button>
                      </Col>
                    </Form.Row>
                  </Form>
                </ListGroup.Item>
              </ListGroup>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  } else {
    return (
      <>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </>
    );
  }
}
