import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

interface IProps {
  data: number[];
}

export default function MetricAreaChart(props: IProps) {
  const temp: number[] = Array.from(props.data).filter((_) => !!_);
  let goal: number | undefined = 0;
  let base: number | undefined = 0;
  let data: any[] = [];

  if (temp.length) {
    base = temp[0];
  }

  if (temp.length > 1) {
    goal = temp.splice(1, 1)[0];
  }

  if (temp.length > 1) {
    data = temp.map((value, i) => ({
      name: "#" + i,
      value,
    }));
  }

  return (
    <div className="mpi-chart" style={{ height: "300px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 30,
            right: 20,
            left: 0,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" domain={[0, Math.max(goal, base, 10) * 1.2]} />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="value"
            stackId="1"
            stroke="var(--primary)"
            fill="var(--primary)"
            fillOpacity={0.2}
            animationDuration={1000}
          />
          {base && (
            <ReferenceLine y={base} stroke="red" strokeWidth={2} label="Base" />
          )}
          {goal && (
            <ReferenceLine
              y={goal}
              stroke="green"
              strokeWidth={2}
              label="Goal"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
