import React from "react";
//import { NavLink } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import InfoBadge from "../../elements/InfoBadge";

import { BrandVision, PrioritizedAreas } from "./";
import { GamePlanList } from "../gameplan";

import PinnedMetrics from "../../elements/PinnedMetrics";

export default function BusinessPlan() {
  return (
    <div className="business-plan">
      <Container fluid>
        <Row className="mb-4">
          <Col>
            <h1>
              My Business Plan{" "}
              <InfoBadge>
                My Business Plan is a high-level summary of the overall goal and
                actions that are to be taken
              </InfoBadge>
            </h1>
            <p>Welcome to your business plan!</p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <BrandVision />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <PrioritizedAreas />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <GamePlanList />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <h2>Performance Results</h2>
            <PinnedMetrics />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
