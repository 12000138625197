import React, { useEffect, useState } from "react";

import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faCaretDown,
  faCaretUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";

import DatePicker from "react-date-picker";

import { Button, Table, Form, Col, Spinner } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";

import Moment from "react-moment";
import InfoBadge from "../../elements/InfoBadge";

import * as api from "../../../services/api/Tasks";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import moment from "moment";
import ConfirmDelete from "../../elements/ConfirmDelete";

interface ITaskPlanListItem {
  uuid: string;
  user: string;
  action: string;
  status: string;
  due: number;
  [key: string]: any;
  editStatus: boolean;
  editDate: boolean;
}

interface ITaskPlansColumn {
  id: string;
  title: string;
}

export type ActionsData = {
  uuid: string;
  taskName: string;
  who: string;
  status: string;
  dueDateUnixTimeMilliseconds: number;
};

const getLastSaved = atom<string>({
  key: "getLastSaved",
  default: "",
});
const getDisabledSaveButton = atom<boolean>({
  key: "getDisabledSaveButton",
  default: true,
});
const getHiddenSpinner = atom<boolean>({
  key: "getHiddenSpinner",
  default: true,
});

export default function Tasks() {
  const [sortBy, setSortBy] = useState({ id: "due", desc: false });
  const [actions, setTasks] = useState<ITaskPlanListItem[]>([]);

  //const uuidActionPlan = "EE0770D4-EC8F-4212-9406-F692256169C2";
  const { uuidActionPlan } = useParams<{ uuidActionPlan: string }>();

  const [newWho, setNewWho] = useState("");
  const [newStatus, setNewStatus] = useState("NOTSTARTED");
  const [newDue, setNewDue] = useState<Date | Date[]>(new Date());
  const [newAction, setNewAction] = useState("");
  const [init, setInit] = useState<boolean | null>(false);

  const [saveText, setSaveText] = useRecoilState(getLastSaved);
  const [disableSaveButton, setDisableSaveButton] = useRecoilState(
    getDisabledSaveButton
  );
  const [hideSpinner, setHideSpinner] = useRecoilState(getHiddenSpinner);

  function addTask() {
    const newUuid = uuid();
    const newTask = {
      uuid: newUuid,
      who: newWho,
      status: newStatus,
      dueDateUnixTimeMilliseconds: Date.parse(newDue.toLocaleString()),
      taskName: newAction,
    };

    api.Tasks.post(uuidActionPlan, newTask).then((response) => {
      if (response.statusText === "OK") {
        const tempArray = Array.from(actions);
        tempArray.push({
          uuid: newTask.uuid,
          due: newTask.dueDateUnixTimeMilliseconds,
          user: newTask.who,
          action: newTask.taskName,
          status: newTask.status,
          editStatus: false,
          editDate: false,
        });
        setTasks(tempArray);
        setNewWho("");
        setNewStatus("NOTSTARTED");
        setNewAction("");
        setNewDue(new Date());
      }
    });
  }

  const deleteTask = (uuid: string) => {
    activeSaveButton();
    api.Tasks.remove(uuid).then((response) => {
      if (response.statusText === "OK") {
        updateSaveButtonAfterSuccess();
        const tempActions = actions.filter((x) => x.uuid !== uuid);
        setTasks(tempActions);
      }
    });
  };

  const confirmDelete = async (data: any, canDelete: boolean) => {
    if (canDelete === true) {
      deleteTask(data);
    }
  };

  const changeDate = (uuid: string, date: Date | Date[]) => {
    let getRecord = getTaskRecord(uuid);
    getRecord.dueDateUnixTimeMilliseconds = Date.parse(date.toLocaleString());
    patchUpdate(getRecord);
    updateUseStateRecord(getRecord);
  };

  const changeStatus = (uuid: string, status: string) => {
    let getRecord = getTaskRecord(uuid);
    getRecord.status = status;
    patchUpdate(getRecord);
    updateUseStateRecord(getRecord);
  };

  const onActionChange = (
    e: any,
    uuid: string,
    enterMode: boolean,
    forcePatch: boolean
  ) => {
    if (enterMode === true) {
      if (e.key === "Enter") {
        let getRecord = getTaskRecord(uuid);
        getRecord.taskName = e.target.value;
        updateUseStateRecord(getRecord);
        e.target.blur();
      }
    } else {
      let getRecord = getTaskRecord(uuid);
      getRecord.taskName = e.target.value;
      updateUseStateRecord(getRecord);
      if (forcePatch === true) {
        patchUpdate(getRecord);
      }
    }
  };

  const onUserChange = (
    e: any,
    uuid: string,
    enterMode: boolean,
    forcePatch: boolean
  ) => {
    if (enterMode === true) {
      if (e.key === "Enter") {
        let getRecord = getTaskRecord(uuid);
        getRecord.who = e.target.value;
        updateUseStateRecord(getRecord);
        e.target.blur();
      }
    } else {
      let getRecord = getTaskRecord(uuid);
      getRecord.who = e.target.value;
      updateUseStateRecord(getRecord);
      if (forcePatch === true) {
        patchUpdate(getRecord);
      }
    }
  };

  function getTaskRecord(uuid: string) {
    const record = actions.filter((x) => x.uuid === uuid)[0];

    return {
      uuid: uuid,
      dueDateUnixTimeMilliseconds: record.due,
      who: record.user,
      taskName: record.action,
      status: record.status,
    };
  }

  function patchUpdate(task: ActionsData) {
    const patchArray = [];
    patchArray.push(task);
    api.Tasks.patch(uuidActionPlan, patchArray)
      .then((response) => {
        if (response.statusText === "OK") {
          updateSaveButtonAfterSuccess();
        }
      })
      .catch(() => {});
  }

  const updateSaveButtonAfterSuccess = () => {
    setSaveText(
      "Last saved at " + moment(new Date().toLocaleString()).format("h:mm A")
    );
    setDisableSaveButton(true);
    setHideSpinner(true);
  };

  const activeSaveButton = () => {
    console.log(saveText + " " + disableSaveButton + " " + hideSpinner);
    setSaveText("");
    setDisableSaveButton(false);
  };

  const updateUseStateRecord = (task: ActionsData) => {
    activeSaveButton();
    setTasks(
      actions.map((item) =>
        item.uuid === task.uuid
          ? {
              ...item,
              editDate: false,
              editStatus: false,
              due: task.dueDateUnixTimeMilliseconds,
              user: task.who,
              status: task.status,
              action: task.taskName,
            }
          : item
      )
    );
  };

  const onStatusClick = (uuid: string) => {
    setTasks(
      actions.map((item) =>
        item.uuid === uuid
          ? { ...item, editStatus: true, editDate: false }
          : { ...item, editStatus: false, editDate: false }
      )
    );
  };

  const onDateClick = (uuid: string) => {
    setTasks(
      actions.map((item) =>
        item.uuid === uuid
          ? { ...item, editDate: true, editStatus: false }
          : { ...item, editDate: false, editStatus: false }
      )
    );
  };

  console.log(saveText + " " + disableSaveButton);

  useEffect(() => {
    if (init === false) {
      api.Tasks.get(uuidActionPlan)
        .then((response) => {
          setInit(true);
          if (response.statusText === "OK") {
            setTasks(
              response.data.map((x) => {
                return {
                  user: x.who,
                  due: x.dueDateUnixTimeMilliseconds,
                  status: x.status,
                  action: x.taskName,
                  uuid: x.uuid,
                  editStatus: false,
                  editDate: false,
                };
              })
            );
          }
        })
        .catch(() => {
          setInit(false);
        });
    }
  }, [init, uuidActionPlan]);

  const columns: ITaskPlansColumn[] = [
    { id: "user", title: "Who" },
    { id: "action", title: "Task" },
    { id: "status", title: "Status" },
    { id: "due", title: "Due Date" },
  ];

  const handleSort = ({ id }: ITaskPlansColumn) => {
    setSortBy({ id, desc: id === sortBy.id ? !sortBy.desc : sortBy.desc });
  };

  if (init === true) {
    return (
      <Col>
        <Form.Label>
          Tasks{" "}
          <InfoBadge>
            Tasks are the step-by-step items that define the Task Plan.
            Assigning an action to an email address will add that email to the
            “Share” section below. As Tasks are marked “Complete” the progress
            bar will fill.
          </InfoBadge>
        </Form.Label>
        <Table bordered className="bg-white">
          <thead className="thead-light">
            <tr>
              {columns.map((_: any, i: number) => (
                <th key={i} className="position-relative">
                  <Button
                    variant="link"
                    onClick={() => handleSort(_)}
                    className="stretched-link text-dark"
                  >
                    <span className="mr-2">{_.title}</span>
                    {sortBy.id === _.id && (
                      <I
                        icon={sortBy.desc ? faCaretDown : faCaretUp}
                        className="text-muted"
                      />
                    )}
                  </Button>
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {actions
              .sort(({ [sortBy.id]: a }, { [sortBy.id]: b }) => {
                let [A, B] = Array(2);
                let val: number = 0;

                switch (typeof a) {
                  case "boolean":
                    [A, B] = [a ? 1 : -1, b ? 1 : -1];
                    val = sortBy.desc ? B - A : A - B;
                    break;
                  case "string":
                    [A, B] = [a.toUpperCase(), b.toUpperCase()];
                    val = (A < B ? -1 : A > B ? 1 : 0) * (sortBy.desc ? -1 : 1);
                    break;
                  case "number":
                    val = sortBy.desc ? b - a : a - b;
                    break;
                }

                return val;
              })
              .map((_: any, i: number) => (
                <tr key={i}>
                  <td>
                    <Form.Control
                      defaultValue={_.user}
                      onBlur={(e: any) => onUserChange(e, _.uuid, false, true)}
                      onChange={(e) => onUserChange(e, _.uuid, false, false)}
                      onKeyDown={(e: any) =>
                        onUserChange(e, _.uuid, true, false)
                      }
                      plaintext={true}
                      value={_.user}
                    />
                  </td>
                  <td>
                    <Form.Control
                      defaultValue={_.action}
                      onBlur={(e: any) =>
                        onActionChange(e, _.uuid, false, true)
                      }
                      onChange={(e) => onActionChange(e, _.uuid, false, false)}
                      onKeyDown={(e: any) =>
                        onActionChange(e, _.uuid, true, false)
                      }
                      plaintext={true}
                      value={_.action}
                    />
                  </td>
                  <td onClick={() => onStatusClick(_.uuid)}>
                    <span hidden={_.editStatus}>
                      {_.status === "COMPLETED"
                        ? "Completed"
                        : _.status === "INPROGRESS"
                        ? "In Progress"
                        : "Not Started"}
                    </span>
                    <Form.Control
                      as="select"
                      defaultValue={_.status}
                      onChange={(e) => changeStatus(_.uuid, e.target.value)}
                      hidden={!_.editStatus}
                      value={_.status}
                    >
                      <option value="NOTSTARTED">Not Started</option>
                      <option value="INPROGRESS">In Progress</option>
                      <option value="COMPLETED">Completed</option>
                    </Form.Control>
                  </td>
                  <td onClick={() => onDateClick(_.uuid)}>
                    <div
                      hidden={_.editDate}
                      className={
                        _.due < Date.now() ? "text-danger font-weight-bold" : ""
                      }
                    >
                      {" "}
                      <Moment format="MM/DD/YYYY">{_.due}</Moment>{" "}
                      <I icon={faCalendarAlt} />
                    </div>
                    <div hidden={!_.editDate}>
                      <DatePicker
                        calendarIcon={<I icon={faCalendarAlt} />}
                        clearIcon={null}
                        value={new Date(_.due)}
                        onChange={(d) => changeDate(_.uuid, d)}
                      />
                    </div>
                  </td>
                  <td className="text-center">
                    <ConfirmDelete
                      defineClass=""
                      data={_.uuid}
                      selection={confirmDelete}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewWho(e.target.value)}
                  defaultValue={newWho}
                  value={newWho}
                  required={true}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewAction(e.target.value)}
                  defaultValue={newAction}
                  value={newAction}
                  required={true}
                />
              </td>
              <td>
                <Form.Control
                  as="select"
                  onChange={(e) => setNewStatus(e.target.value)}
                  defaultValue={newStatus}
                  value={newStatus}
                >
                  <option value="NOTSTARTED">Not Started</option>
                  <option value="INPROGRESS">In Progress</option>
                  <option value="COMPLETED">Completed</option>
                </Form.Control>
              </td>
              <td>
                <DatePicker
                  calendarIcon={<I icon={faCalendarAlt} />}
                  clearIcon={null}
                  value={newDue}
                  onChange={(d) => setNewDue(d)}
                />
              </td>
              <td className="text-center">
                <Button
                  className="px-3"
                  onClick={() => addTask()}
                  type="submit"
                >
                  <I icon={faPlus} />
                </Button>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Col>
    );
  } else {
    return (
      <>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </>
    );
  }
}
