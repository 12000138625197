import React, { useEffect, useState } from "react";

import RichText from "../../elements/RichText";
import InfoBadge from "../../elements/InfoBadge";
import { Spinner } from "react-bootstrap";

import * as api from "../../../services/api/BrandVision";

export default function BrandVision() {
  const [textData, setTextData] = useState<string>(`
  <h2>At Subaru,</h2>
  <p>
    Our mission is to <i><strong>LOVE</strong></i> the value of your time. We <i><strong>PROMISE</strong></i> to earn your trust while providing our customers with extraordinary service. We focus on building lifelong relationships by welcoming you to our Subaru family.
  </p>`);
  // ReactDOMServer.renderToStaticMarkup(doc)

  const [init, setInit] = useState<boolean | null>(false);

  useEffect(() => {
    api.BrandVision.get().then((res) => {
      if (res.status === 200) {
        setTextData(res.data);
        setInit(true);
      }
    });
  }, [setInit]);

  const updateHandler = async (str: string, id: any) => {
    setTextData(str);
    const res = await api.BrandVision.put(str);
    if (res.status === 200) {
      //setTextData(str); --> creating a race condition where multiple updates not getting in sync, moving before call seemed to help
    }
  };

  if (init === true) {
    return (
      <>
        <h2>
          Brand Vision{" "}
          <InfoBadge>
            Brand Vision describes what the operation stands for and is working
            to accomplish
          </InfoBadge>
        </h2>

        <RichText id="myBrandVision" data={textData} update={updateHandler} />
      </>
    );
  } else {
    return (
      <>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </>
    );
  }
}
