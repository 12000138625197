import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Nav,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faUser } from "@fortawesome/free-regular-svg-icons";

import BusinessPlan from "./components/pages/businessplan";
import GamePlan from "./components/pages/gameplan/GamePlan";
import Workbooks from "./components/pages/Workbooks";
import Workbook from "./components/pages/Workbook";
import Profile, {
  IProfile,
  ProfileMode,
  ProfileStatus,
  ProfileUser,
} from "./components/pages/Profile";

import * as api from "./services/api/Auth";

import "./scss/App.scss";
import { GamePlanList } from "./components/pages/gameplan";

import client from "./services/api/base";

export default function App() {
  const navHandler = (key: any, e: any) => {
    // e.preventDefault();
    // locationKey = key;
    // history.push(key);
  };

  const profileUpdateHandler = (data: IProfile) => {
    if (data.mode === ProfileMode.EDIT && data.status === ProfileStatus.HIDE) {
      setProfile({ ...data });
      return;
    }

    if (data.user) {
      let status: ProfileStatus;
      setProfile({ ...data, status: ProfileStatus.SAVING });

      client
        .put("/v2/user", data.user)
        .then(() => (status = ProfileStatus.SUCCESS))
        .catch(() => (status = ProfileStatus.FAILED))
        .finally(() => {
          let newProfile: IProfile = { ...data, status: status };

          setProfile(newProfile);
          if (status === ProfileStatus.SUCCESS) {
            setTimeout(() => {
              newProfile = { ...newProfile, status: ProfileStatus.HIDE };
              setProfile(newProfile);

              setTimeout(() => {
                newProfile = {
                  ...newProfile,
                  mode: ProfileMode.EDIT,
                  user: data.user,
                };
                setProfile(newProfile);
              }, 500);
            }, 2000);
          }
        });
    }
  };

  const [profile, setProfile] = useState<IProfile>({
    mode: ProfileMode.CREATE,
    status: ProfileStatus.HIDE,
    jobTitles: [],
    titleStatus: true,
    user: {
      subaruUserID: "",
      firstName: "",
      lastName: "",
      email: "",
      jobTitleId: NaN,
      retailer: "",
      district: "",
      zone: "",
      region: "",
    },
    onUpdate: profileUpdateHandler,
  });

  const [init, setInit] = useState<boolean | null>(false);

  useEffect(() => {
    api.Auth.post()
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.token);
          client.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.token;
          return res;
        } else {
          throw Error(res.statusText);
        }
      })
      .then(() => {
        return client.get("/v2/user").then((res) => {
          if (res.statusText !== "OK") {
            setInit(null);
            throw new Error(res.statusText);
          }
          return res;
        });
      })
      .then((res) => res.data)
      .then((user: ProfileUser) => {
        setProfile((_) => ({ ..._, user }));
        setInit(true);

        return client.get("/v1/user/status");
      })
      .then((res) => {
        if (res.statusText !== "OK") {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.data)
      .then((json) => {
        const isActive = json.status === "ACTIVE";
        setProfile((_) => ({
          ..._,
          mode: isActive ? ProfileMode.EDIT : ProfileMode.CREATE,
          status: isActive ? ProfileStatus.HIDE : ProfileStatus.NORMAL,
        }));

        return client.get("/v1/job/titles");
      })
      .then((res) => {
        if (res.statusText !== "OK") {
          setProfile((_) => ({ ..._, titleStatus: false }));
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.data)
      .then((json) => setProfile((_) => ({ ..._, jobTitles: json })))
      .catch((err) => console.error);
  }, []);

  return init === null ? (
    <Modal
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
    >
      <Modal.Header>
        <Modal.Title>
          <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
          Server error
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Cannot find user, try again in a bit. <br />
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => window.location.reload(false)}
          className="m-auto"
        >
          Refresh page.
        </Button>
      </Modal.Footer>
    </Modal>
  ) : !init ? (
    <Container
      fluid
      className="d-flex h-100 position-absolute justify-content-center"
    >
      <div className="align-self-center">
        <Spinner animation="border" role="status" size="sm" className="mr-2" />
        Loading profile
      </div>
    </Container>
  ) : (
    <div className="App">
      <Container fluid>
        <Row className="py-2">
          <img src="./BlueRectangle.png" alt="Logo" />
          <h1
            className="float-right"
            style={{
              color: "#00B0F0",
              marginLeft: "10px",
              paddingTop: "5px",
            }}
          >
            Business Plan
          </h1>

          {profile.user && (
            <Col className="text-right">
              <strong className="d-none d-md-inline">
                Hi, {profile.user.firstName}
              </strong>
              <Button
                variant="link"
                className="ml-3 p-0"
                onClick={() => {
                  setProfile({
                    ...profile,
                    status: ProfileStatus.NORMAL,
                  });
                }}
              >
                <FontAwesomeIcon size="lg" icon={faUser} />
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Container fluid>
            <Row>
              <Container>
                <Nav className="main-nav" onSelect={navHandler}>
                  {[
                    { to: "/business-plan", title: "My Business Plan" },
                    { to: "/workbooks/1", title: "Workbooks" },
                    {
                      to: "/game-plans",
                      title: "Game Plans",
                    },
                  ].map((_, i) => (
                    <Nav.Item key={i}>
                      <LinkContainer to={_.to}>
                        <Nav.Link className="text-dark" active={false}>
                          {_.title}
                        </Nav.Link>
                      </LinkContainer>
                    </Nav.Item>
                  ))}
                </Nav>
              </Container>
            </Row>
            <Row className="bg-light">
              <Container className="py-5">
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/business-plan" />
                  </Route>
                  <Route path="/business-plan" component={BusinessPlan} />
                  <Route
                    path="/game-plan/:uuidActionPlan"
                    component={GamePlan}
                  />
                  <Route path="/game-plans" component={GamePlanList} />
                  <Route path="/workbooks/:workbookId" component={Workbook} />
                  <Route path="/workbooks" component={Workbooks} />
                  <Route>
                    <h1>404 Page not found</h1>
                    <Link to="/">Home page</Link>
                  </Route>
                </Switch>
              </Container>
            </Row>
          </Container>
        </Row>
      </Container>

      <Profile {...profile} />
    </div>
  );
}
