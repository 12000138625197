import { get, post, patch, destroy } from "./base";

import { MetricApiData } from "../../components/elements/Metrics";

export const Metrics = {
  get: (uuid: string) =>
    get<MetricApiData[]>("/v1/actionplan/" + uuid + "/metrics"),
  post: (uuid: string, data: MetricApiData) =>
    post<MetricApiData>("/v1/actionplan/" + uuid + "/metrics", data),
  patch: (uuid: string, data: MetricApiData[]) =>
    patch<MetricApiData[]>("/v1/actionplan/" + uuid + "/metrics", data),
  remove: (uuid: string) => destroy("/v1/metric/" + uuid),
};
